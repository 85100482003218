const initialState = {
    bankAccountList: [],
    bankList: []
}

//trigger action
const bankAccount = (state = initialState, action) => {
    const newState = { ...state }

    switch (action.type) {
        case 'GET_BANK_ACCOUNT_LIST':
            break
        case 'GET_BANK_ACCOUNT_LIST_SUCCESS':
            newState.bankAccountList = action.data.account
            // newState.token = action.data.token
            break
        case 'GET_BANK_LIST':
            //newState.age += action.value;
            break
        case 'GET_BANK_LIST_SUCCESS':
            newState.bankList = action.data.list
            break
        default:
            return state
    }

    return newState
}

export default bankAccount
