const initialState = {
    drawModeList: [],
    drawModeActive: []
}

//trigger action
const drawmode = (state = initialState, action) => {
    const newState = { ...state }

    switch (action.type) {
        case 'GET_DRAW_MODE_LIST':
            break
        case 'GET_DRAW_MODE_LIST_SUCCESS':
            newState.drawModeList = action.data.list
            // newState.token = action.data.token
            break

        case 'GET_DRAW_MODE_ACTIVE':
            break
        case 'GET_DRAW_MODE_ACTIVE_SUCCESS':
            newState.drawModeActive = action.data.list
            // newState.token = action.data.token
            break
        case 'UPDATE_DRAW_MODE':
            // console.log('here UPDATE_DRAW_MODE')
            // newState.token = action.data.token
            break

        default:
            return state
    }

    return newState
}

export default drawmode
