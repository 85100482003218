import { all } from 'redux-saga/effects'
//import testSaga from "./test_saga";
import login_saga from './login_saga'
import user_saga from './user_saga'
import wallet_saga from './wallet_saga'
import portal_saga from './portal_saga'
import hot_saga from './hot_saga'
import bank_account_saga from './bank_account_saga'
import report_saga from './report_saga'
import availabl_days_saga from './available_days_saga'
import user_permission_saga from './user_permission_saga'
import promo_saga from './promo_saga'
import admin_saga from './admin_saga'
import draw_saga from './draw_saga'
import draw_time_saga from './draw_time_saga'
import draw_mode_saga from './draw_mode_saga'
import wheel_saga from './wheel_saga'

export default function* rootSaga() {
    yield all([
        //  testSaga(),
        login_saga(),
        user_saga(),
        wallet_saga(),
        portal_saga(),
        hot_saga(),
        bank_account_saga(),
        report_saga(),
        availabl_days_saga(),
        user_permission_saga(),
        promo_saga(),
        admin_saga(),
        draw_saga(),
        draw_time_saga(),
        draw_mode_saga(),
        wheel_saga()
    ])
}
