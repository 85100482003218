const initialState = {
    userList: [],
    userExportList: [],
    userListPage: 1,
    userDetail: {}
}

//trigger action
const user = (state = initialState, action) => {
    const newState = { ...state }
    
    switch (action.type) {
        case 'GET_USER_LIST':
            break
        case 'GET_USER_LIST_SUCCESS':
            newState.userList = action.data
            newState.userListPage = action.data.total_page
            break
        case 'GET_USER_EXPORT':
            break
        case 'GET_USER_EXPORT_SUCCESS':
            newState.userExportList = action.data
            break
        case 'GET_USER_LIST_AGENT':
            break
        case 'GET_USER_LIST_AGENT_SUCCESS':
            newState.userList = action.data
            newState.userListPage = action.data.total_page
            break
        case 'INITIAL_USER_DETAIL':
            // console.log('show update usser ' + JSON.stringify(action.data))
            newState.userDetail = action.data
            break
        case 'UPDATE_USER_STATUS':
            break
        default:
            return state
    }

    return newState
}

export default user
