import instance from "../index";

// Base Path
export const basePath = () => {
  return "/api/user";
};

export const getUserList = (page = 1, data = {}) => {
  const url = `${basePath()}/${page}`;

  let req = {
    params: data
  };

  return instance.get(url, req);
};

export const getUserListAgent = (page = 1) => {
  const url = `/api/user/byagent/${page}`;

  return instance.get(url);
};

export const getUserExport = (data = {}) => {
  const url = `${basePath()}/export`;

  let req = {
    params: data
  };

  return instance.get(url, req);
};

export const updateUserStatus = params => {
  const url = `${basePath()}/status/update`;

  return instance.post(url, params);
};

export const masterUpdateUserProfile = params => {
  const url = `${basePath()}/byadmin/profile/update`;

  return instance.post(url, params);
};

export const createUser = params => {
  const url = `${basePath()}/create`;

  return instance.post(url, params);
};

export const createUserAgent = params => {
  const url = `${basePath()}/agent/create`;

  return instance.post(url, params);
};

export const resetUserPassword = params => {
  const url = `${basePath()}/byadmin/resetpassword`;

  return instance.post(url, params);
};

export const createGalaxyAccount = params => {
  const url = `${basePath()}/galaxy/create`;

  return instance.post(url, params);
};
