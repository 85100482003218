import { put, takeEvery, all, fork } from 'redux-saga/effects'

import { store } from '../index'
import * as API from '../../api'

export function* getDrawModeList() {
    yield takeEvery('GET_DRAW_MODE_LIST', function*(action) {
        // console.log('start GET_DRAW_MODE_LIST')

        store.dispatch({ type: 'LOADER_SHOW' })

        const response = yield API.getDrawModeList()
        try {
            if (response) {
                let data = response.data

                if (!data.success) alert('Error:\n ' + 'failed to get data')
                else {
                    // console.log('return saga getModeList')
                    // //console.log("get portal activity "+JSON.stringify(data))
                    yield put({ type: 'GET_DRAW_MODE_LIST_SUCCESS', data })

                    // this.props.history.push("/dashboard");
                }
            }
        } catch (error) {
            console.log(error)
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })
}

export function* getDrawModeActive() {
    yield takeEvery('GET_DRAW_MODE_ACTIVE', function*(action) {
        // console.log('start GET_DRAW_MODE_ACTIVE')

        store.dispatch({ type: 'LOADER_SHOW' })

        const response = yield API.getDrawModeActive()
        try {
            if (response) {
                let data = response.data

                if (!data.success) alert('Error:\n ' + 'failed to get data')
                else {
                    // console.log('return saga getDrawModeActive')
                    // //console.log("get portal activity "+JSON.stringify(data))
                    yield put({ type: 'GET_DRAW_MODE_ACTIVE_SUCCESS', data })

                    // this.props.history.push("/dashboard");
                }
            }
        } catch (error) {
            console.log(error)
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })
}

export function* updateDrawMode() {
    yield takeEvery('UPDATE_DRAW_MODE', function*(action) {
        yield put({ type: 'LOADER_SHOW' })
        //get params
        let params = action.params

        const response = yield API.updateDrawMode(params)
        try {
            //  //console.log("show response "+JSON.stringify(response))
            if (response) {
                let data = response.data

                if (data.success) {
                    // //console.log("update wallet activity "+JSON.stringify(data))
                    // this.props.history.push("/dashboard");
                    yield put({ type: 'SHOW_MESSAGE', msg_type: 'UPDATE' })
                    yield put({ type: 'GET_DRAW_MODE_ACTIVE' })
                } else alert('Error:\n ' + 'failed to get data')
            }
        } catch (error) {
            console.log(error)
        }

        yield put({ type: 'LOADER_HIDE' })
    })
}

export default function* draw_time_saga() {
    yield all([fork(getDrawModeList), fork(getDrawModeActive), fork(updateDrawMode)])
}
