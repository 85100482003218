import instance from '../index'

// Base Path
export const basePath = () => {
    return '/api/bank/account'
}

export const getBankList = () => {
    const url = `/api/bank`

    return instance.get(url)
}

export const getBankAccount = () => {
    const url = `${basePath()}`

    return instance.get(url)
}

export const createBankAccount = params => {
    const url = `${basePath()}/create`

    // {
    //     "id": "1",
    //     "name": "test name",
    //     "number": "1234567890"
    // }

    return instance.post(url, params)
}

export const updateBankAccount = params => {
    const url = `${basePath()}/update`

    //   {
    //     "id": "1",
    //     "status" : "1"
    // }

    return instance.post(url, params)
}

export const deleteBankAccount = params => {
    const url = `${basePath()}/delete`

    return instance.post(url, params)
}
