import instance from '../index'

// Base Path
export const basePath = () => {
    return '/api/mode'
}

export const getDrawModeList = () => {
    const url = `${basePath()}`

    return instance.get(url)
}

export const getDrawModeActive = () => {
    const url = `${basePath()}/active`

    return instance.get(url)
}



export const updateDrawMode = params => {
    const url = `${basePath()}/update`

    return instance.post(url, params)
}

