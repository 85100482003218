const initialState = {
    adminList: [],
    adminDetail: {},
    adminProfile: []
}

//trigger action
const admin = (state = initialState, action) => {
    const newState = { ...state }

    switch (action.type) {
        case 'GET_ADMIN_LIST':
            break
        case 'GET_ADMIN_LIST_SUCCESS':
            newState.adminList = action.data
            // newState.token = action.data.token
            break
        case 'GET_OWN_ADMIN_LIST':
            break
        case 'GET_OWN_ADMIN_LIST_SUCCESS':
            newState.adminList = action.data
            // newState.token = action.data.token
            break
        case 'INITIAL_ADMIN_DETAIL':
            newState.adminDetail = action.data
            break
        case 'UPDATE_ADMIN':
            break
        case 'UPDATE_OWN_ADMIN':
            break
        case 'GET_ADMIN_PROFILE_SUCCESS':
            newState.adminProfile = action.data.profile
            break

        default:
            return state
    }

    return newState
}

export default admin
