
const initialState = {
  portalList: []
};

//trigger action 
const portal = (state = initialState, action) => {
  const newState = { ...state };

  switch (action.type) {
    case "GET_PORTAL_LIST":
      break;
    case "GET_PORTAL_LIST_SUCCESS":
       newState.portalList = action.data.list
      // newState.token = action.data.token
      break;
    default:
      return state;
  }


  return newState;
};

export default portal;