const initialState = {
    drawList: []
}

//trigger action
const hot = (state = initialState, action) => {
    const newState = { ...state }

    switch (action.type) {
        case 'GET_DRAW_LIST':
            break
        case 'GET_DRAW_LIST_SUCCESS':
            newState.drawList = action.data.list
            // newState.token = action.data.token
            break
        case 'UPDATE_DRAW':
            // console.log('here UPDATE_DRAW')
            // newState.token = action.data.token
            break

        default:
            return state
    }

    return newState
}

export default hot
