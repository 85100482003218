import instance from '../index'

// Base Path
export const basePath = () => {
    return '/api/admin'
}

export const getAdminList = (page = 1) => {
    const url = `${basePath()}/${page}`

    return instance.get(url)
}

export const getOwnAdminList = (page = 1) => {
    const url = `${basePath()}/own/${page}`

    return instance.get(url)
}

export const updateAdmin = params => {
    const url = `${basePath()}/update`

    return instance.post(url, params)
}

export const masterUpdateAdmin = params => {
    const url = `${basePath()}/byadmin/profile/update`

    return instance.post(url, params)
}

export const createAdmin = params => {
    const url = `${basePath()}/create`

    return instance.post(url, params)
}

export const getAdminProfile = () => {
    const url = `/api/admin/profile `

    return instance.get(url)
}

export const changeUserPassword = params => {
    const url = `${basePath()}/changepassword/update`

    return instance.post(url, params)
}
