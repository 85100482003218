import instance from '../index'

// Base Path
export const basePath = () => {
    return '/api'
}

export const getUserPermission = () => {
    const url = `${basePath()}/admin/permission`

    return instance.get(url)
}

export const getPermissionList = () => {
    const url = `${basePath()}/role/permission`

    return instance.get(url)
}

export const getRoleList = () => {
    const url = `${basePath()}/role`

    return instance.get(url)
}

export const updateRole = params => {
    const url = `${basePath()}/role/update`

    return instance.post(url, params)
}

export const createRole = params => {
    const url = `${basePath()}/role/create`

    return instance.post(url, params)
}

export const deleteRole = params => {
    const url = `${basePath()}/role/delete`

    return instance.post(url, params)
}

// export const updateWallet = (params) => {
//   const url = `${basePath()}/update`;

//   return instance.post(url,params);
// };
