const initialState = {
    walletList: [],
    walletExportList: [],
    walletListPage: 1
}

//trigger action
const user = (state = initialState, action) => {
    const newState = { ...state }

    switch (action.type) {
        case 'GET_WALLET_LIST':
            break
        case 'GET_WALLET_LIST_SUCCESS':
            newState.walletList = action.data.activity
            newState.walletListPage = action.data.total_page
            // newState.token = action.data.token
            break
        case 'GET_FINANCIAL_LOG_LIST':
            break
        case 'GET_FINANCIAL_LOG_LIST_SUCCESS':
            newState.walletList = action.data.list
            newState.walletListPage = action.data.total_page
            // newState.token = action.data.token
            break
        case 'GET_FINANCIAL_LOG_EXPORT_LIST':
            break
        case 'GET_FINANCIAL_LOG_EXPORT_LIST_SUCCESS':
            newState.walletExportList = action.data.list
            // newState.token = action.data.token
            break
        case 'UPDATE_WALLET_ACTIVITY':
            break
        default:
            return state
    }

    return newState
}

export default user
