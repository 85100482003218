import instance from '../index'

// Base Path
export const basePath = () => {
    return '/api/hot'
}

export const getHotList = () => {
    const url = `${basePath()}`

    return instance.get(url)
}

export const createHotNumber = params => {
    const url = `${basePath()}/create`

    return instance.post(url, params)
}

export const updateHotNumber = params => {
    const url = `${basePath()}/update`

    //   {
    //   "id": "1",
    //   "limit" : "40",
    //   "limituser" : "2"
    // }

    return instance.post(url, params)
}

export const deleteHotNumber = params => {
    const url = `${basePath()}/delete`

    //   {
    //     "id": "1"
    // }

    return instance.post(url, params)
}
