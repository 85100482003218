import instance from '../index'

// Base Path
export const basePath = () => {
    return '/api/wallet/activity'
}

export const getWalletList = (page = 1, status = 0) => {
    // 0 = Pending
    // 1 = Approved
    // 2 = Reject

    const url = `${basePath()}/${status}/${page}`

    return instance.get(url)
}

export const getFinancialLogList = (page, data = {}) => {
    const url = `${basePath()}/financiallog/${page}`

    let req = {
        params: data
    }
    return instance.get(url, req)
}

export const getFinancialLogExportList = (data = {}) => {
    const url = `${basePath()}/financiallog/export`

    let req = {
        params: data
    }
    return instance.get(url, req)
}

export const createWallet = params => {
    const url = `${basePath()}/create`

    params = {
        amount: '0.00',
        type: '1',
        transactiontype: '1',
        path: '/path/image/pdf',
        status: '0'
    }

    return instance.post(url, params)
}

export const updateWallet = params => {
    const url = `${basePath()}/update`

    return instance.post(url, params)
}

export const manualCreateWallet = params => {
    const url = `${basePath()}/create`
    return instance.post(url, params)
}
