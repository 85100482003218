import { put, takeEvery, all, fork } from 'redux-saga/effects'
import { store } from '../index'
import * as API from '../../api'

export function* getAdminList() {
    yield takeEvery('GET_ADMIN_LIST', function*(action) {
        console.log('start get admin list')

        let page = action.page

        store.dispatch({ type: 'LOADER_SHOW' })
        const response = yield API.getAdminList(page)

        // console.log('show response ' + JSON.stringify(response))

        try {
            if (response) {
                let data = response.data

                if (!data.success) alert('Error:\n ' + 'failed to get data')
                else {
                    // //console.log("get user data "+JSON.stringify(data))
                    yield put({ type: 'GET_ADMIN_LIST_SUCCESS', data })

                    // this.props.history.push("/dashboard");
                }
            }
        } catch (error) {
            //console.log(error)
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })
}

export function* getOwnAdminList() {
    yield takeEvery('GET_OWN_ADMIN_LIST', function*(action) {
        console.log('start get admin list')

        let page = action.page

        store.dispatch({ type: 'LOADER_SHOW' })
        const response = yield API.getOwnAdminList(page)

        // console.log('show response ' + JSON.stringify(response))

        try {
            if (response) {
                let data = response.data

                if (!data.success) alert('Error:\n ' + 'failed to get data')
                else {
                    // //console.log("get user data "+JSON.stringify(data))
                    yield put({ type: 'GET_OWN_ADMIN_LIST_SUCCESS', data })

                    // this.props.history.push("/dashboard");
                }
            }
        } catch (error) {
            //console.log(error)
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })
}

export function* updateAdmin() {
    yield takeEvery('UPDATE_ADMIN', function*(action) {
        // console.log('start update user states')

        store.dispatch({ type: 'LOADER_SHOW' })

        const response = yield API.updateAdmin(action.params)

        try {
            if (response) {
                let data = response.data

                if (data.success) {
                    // //console.log("update user states "+JSON.stringify(data))
                    // this.props.history.push("/user");
                    store.dispatch({ type: 'GET_ADMIN_LIST' })

                    store.dispatch({ type: 'SHOW_MESSAGE', msg_type: 'UPDATE' })
                } else alert('Error:\n ' + 'failed to get data')
            }
        } catch (error) {
            console.log(error)
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })
}

export function* masterUpdateAdmin() {
    yield takeEvery('MASTER_UPDATE_ADMIN', function*(action) {
        // console.log('start MASTER_UPDATE_ADMIN')

        store.dispatch({ type: 'LOADER_SHOW' })

        const response = yield API.masterUpdateAdmin(action.params)

        try {
            if (response) {
                let data = response.data

                if (data.success) {
                    // //console.log("update user states "+JSON.stringify(data))
                    // this.props.history.push("/user");
                    store.dispatch({ type: 'GET_ADMIN_LIST' })

                    store.dispatch({ type: 'SHOW_MESSAGE', msg_type: 'UPDATE' })
                } else alert('Error:\n ' + 'failed to get data')
            }
        } catch (error) {
            console.log(error)
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })
}

export function* updateOwnAdmin() {
    yield takeEvery('UPDATE_OWN_ADMIN', function*(action) {
        // console.log('start update user states')

        store.dispatch({ type: 'LOADER_SHOW' })

        const response = yield API.updateAdmin(action.params)

        try {
            if (response) {
                let data = response.data

                if (data.success) {
                    // //console.log("update user states "+JSON.stringify(data))
                    // this.props.history.push("/user");
                    store.dispatch({ type: 'GET_OWN_ADMIN_LIST' })

                    store.dispatch({ type: 'SHOW_MESSAGE', msg_type: 'UPDATE' })
                } else alert('Error:\n ' + 'failed to get data')
            }
        } catch (error) {
            console.log(error)
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })
}

export function* masterUpdateOwnAdmin() {
    yield takeEvery('MASTER_UPDATE_OWN_ADMIN', function*(action) {
        // console.log('start MASTER_UPDATE_ADMIN')

        store.dispatch({ type: 'LOADER_SHOW' })

        const response = yield API.masterUpdateAdmin(action.params)

        try {
            if (response) {
                let data = response.data

                if (data.success) {
                    // //console.log("update user states "+JSON.stringify(data))
                    // this.props.history.push("/user");
                    store.dispatch({ type: 'GET_OWN_ADMIN_LIST' })

                    store.dispatch({ type: 'SHOW_MESSAGE', msg_type: 'UPDATE' })
                } else alert('Error:\n ' + 'failed to get data')
            }
        } catch (error) {
            console.log(error)
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })
}

export function* createAdmin() {
    yield takeEvery('CREATE_ADMIN', function*(action) {
        // console.log('start CREATE_ADMIN')

        store.dispatch({ type: 'LOADER_SHOW' })

        const response = yield API.createAdmin(action.params)

        try {
            if (response) {
                let data = response.data

                if (data.success) {
                    // //console.log("update user states "+JSON.stringify(data))
                    // this.props.history.push("/user");
                    store.dispatch({ type: 'GET_ADMIN_LIST' })

                    store.dispatch({ type: 'SHOW_MESSAGE', msg_type: 'CREATE' })
                } else alert('Error:\n ' + 'failed to get data')
            }
        } catch (error) {
            console.log(error)
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })
}


export function* createOwnAdmin() {
    yield takeEvery('CREATE_OWN_ADMIN', function*(action) {
        // console.log('start CREATE_ADMIN')

        store.dispatch({ type: 'LOADER_SHOW' })

        const response = yield API.createAdmin(action.params)

        try {
            if (response) {
                let data = response.data

                if (data.success) {
                    // //console.log("update user states "+JSON.stringify(data))
                    // this.props.history.push("/user");
                    store.dispatch({ type: 'GET_OWN_ADMIN_LIST' })

                    store.dispatch({ type: 'SHOW_MESSAGE', msg_type: 'CREATE' })
                } else alert('Error:\n ' + 'failed to get data')
            }
        } catch (error) {
            console.log(error)
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })
}

export function* getAdminProfile() {
    yield takeEvery('GET_ADMIN_PROFILE', function*(action) {
        // console.log('start get GET_ADMIN_PROFILE')

        store.dispatch({ type: 'LOADER_SHOW' })

        try {
            const response = yield API.getAdminProfile()
            // console.log('show response ' + JSON.stringify(response))
            if (response) {
                let data = response.data

                if (!data.success) alert('Error:\n ' + 'failed to get data')
                else {
                    // //console.log("get user data "+JSON.stringify(data))
                    yield put({ type: 'GET_ADMIN_PROFILE_SUCCESS', data })

                    // this.props.history.push("/dashboard");
                }
            }
        } catch (error) {
            console.log(error)
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })
}



export function* changePassword() {
    yield takeEvery('USER_CHANGE_PASSWORD', function*(action) {
        //console.log('start CREATE_USER')
        store.dispatch({ type: 'LOADER_SHOW' })

        const response = yield API.changeUserPassword(action.params)

        try {
            if (response) {
                let data = response.data
                if (data.success) {
                    // //console.log("update user states "+JSON.stringify(data))
                    // this.props.history.push("/user");

                    store.dispatch({ type: 'SHOW_MESSAGE', msg_type: 'CHANGE_PASSWORD' })
                } else {
                    alert('Error:\n ' + 'failed change password')
                }
            }
        } catch (error) {
            //console.log(error)
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })
}

export default function* admin_saga() {
    yield all([
        fork(getAdminList),
        fork(getOwnAdminList),
        fork(updateAdmin),
        fork(updateOwnAdmin),
        fork(createAdmin),
        fork(createOwnAdmin),
        fork(getAdminProfile),
        fork(masterUpdateAdmin),
        fork(masterUpdateOwnAdmin),
        fork(changePassword)
    ])
}
