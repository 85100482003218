import { put, takeEvery, all, fork } from 'redux-saga/effects'
import { push } from 'react-router-redux'

import { store } from '../index'
import * as API from '../../api'

export function* getPermissionList() {
    yield takeEvery('GET_USER_PERMISSION', function*(action) {
        //console.log('start get GET_USER_PERMISSION')

        store.dispatch({ type: 'LOADER_SHOW' })
        const response = yield API.getUserPermission()

        try {
            if (response) {
                let data = response.data

                if (data.success) {
                    // console.log('get GET_PERMISSION_LIST ' + JSON.stringify(data))
                    yield put({ type: 'GET_USER_PERMISSION_SUCCESS', data })

                    let permissionList = JSON.parse(data.permissionlist)
                    let isAdmin = permissionList.includes(1)

                    if (isAdmin) {
                        yield put(push('/dashboard'))
                    } else {
                        yield put(push('/agent_dashboard'))
                    }

                    // this.props.history.push("/dashboard");
                } else alert('Error:\n ' + 'failed to get data')
            }
        } catch (error) {
            //console.log(error)
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })

    yield takeEvery('GET_PERMISSION_LIST', function*(action) {
        //console.log('start get GET_PERMISSION_LIST')

        store.dispatch({ type: 'LOADER_SHOW' })

        try {
            const response = yield API.getPermissionList()
            if (response) {
                let data = response.data

                if (data.success) {
                    // //console.log('get GET_PERMISSION_LIST ' + JSON.stringify(data))
                    yield put({ type: 'GET_PERMISSION_LIST_SUCCESS', data })

                    // this.props.history.push("/dashboard");
                } else alert('Error:\n ' + 'failed to get data')
            }
        } catch (error) {
            //console.log(error)
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })

    yield takeEvery('GET_ROLE_LIST', function*(action) {
        //console.log('start get GET_ROLE_LIST')

        store.dispatch({ type: 'LOADER_SHOW' })

        try {
            const response = yield API.getRoleList()
            if (response) {
                let data = response.data

                if (data.success) {
                    // console.log('get GET_PERMISSION_LIST ' + JSON.stringify(data))
                    yield put({ type: 'GET_ROLE_LIST_SUCCESS', data })

                    // this.props.history.push("/dashboard");
                } else alert('Error:\n ' + 'failed to get data')
            }
        } catch (error) {
            //console.log(error)
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })

    yield takeEvery('UPDATE_ROLE', function*(action) {
        //console.log('start get UPDATE_ROLE')

        store.dispatch({ type: 'LOADER_SHOW' })

        let params = action.params

        // //console.log("show response "+JSON.stringify(response))
        try {
            const response = yield API.updateRole(params)
            if (response) {
                let data = response.data

                if (data.success) {
                    yield put({ type: 'SHOW_MESSAGE', msg_type: 'UPDATE' })
                    //yield put({ type: 'GET_ANNOUCEMENT_LIST' })

                    //this.props.history.push("/role");

                    yield put(push('/role'))
                } else alert('Error:\n ' + 'failed to get data')
            }
        } catch (error) {
            //console.log(error)
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })

    yield takeEvery('CREATE_ROLE', function*(action) {
        //console.log('start get CREATE_ROLE')

        store.dispatch({ type: 'LOADER_SHOW' })

        let params = action.params

        const response = yield API.createRole(params)

        // //console.log("show response "+JSON.stringify(response))
        try {
            if (response) {
                let data = response.data

                if (data.success) {
                    yield put({ type: 'SHOW_MESSAGE', msg_type: 'CREATE' })
                    //yield put({ type: 'GET_ANNOUCEMENT_LIST' })

                    //this.props.history.push("/role");

                    yield put(push('/role'))
                } else alert('Error:\n ' + 'failed to get data')
            }
        } catch (error) {
            //console.log(error)
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })
}

export default function* user_permission_saga() {
    yield all([fork(getPermissionList)])
}
