import { put, takeEvery, all, fork } from "redux-saga/effects";
import { store } from "../index";
import * as API from "../../api";

export function* getUserList() {
  yield takeEvery("GET_USER_LIST", function*(action) {
    //console.log('start get user list')

    store.dispatch({ type: "LOADER_SHOW" });
    const response = yield API.getUserList(action.page, action.params);

    //  //console.log("show response "+JSON.stringify(response))

    try {
      if (response) {
        let data = response.data;

        if (!data.success) alert("Error:\n " + "failed to get data");
        else {
          // //console.log("get user data "+JSON.stringify(data))
          yield put({ type: "GET_USER_LIST_SUCCESS", data });

          // this.props.history.push("/dashboard");
        }
      }
    } catch (error) {
      //console.log(error)
    }

    store.dispatch({ type: "LOADER_HIDE" });
  });
}

export function* getUserExport() {
  yield takeEvery("GET_USER_EXPORT", function*(action) {
    //console.log('start get user list')

    store.dispatch({ type: "LOADER_SHOW" });
    const response = yield API.getUserExport(action.params);

    //console.log("show response "+JSON.stringify(response))

    try {
      if (response) {
        let data = response.data;

        if (!data.success) alert("Error:\n " + "failed to get data");
        else {
          // //console.log("get user data "+JSON.stringify(data))
          yield put({ type: "GET_USER_EXPORT_SUCCESS", data });

          // this.props.history.push("/dashboard");
        }
      }
    } catch (error) {
      //console.log(error)
    }

    store.dispatch({ type: "LOADER_HIDE" });
  });
}

export function* getUserListAgent() {
  yield takeEvery("GET_USER_LIST_AGENT", function*(action) {
    //console.log('start GET_USER_LIST_AGENT')

    store.dispatch({ type: "LOADER_SHOW" });
    const response = yield API.getUserListAgent(action.page);

    //  //console.log("show response "+JSON.stringify(response))

    try {
      if (response) {
        let data = response.data;

        if (!data.success) alert("Error:\n " + "failed to get data");
        else {
          // //console.log("get user data "+JSON.stringify(data))
          yield put({ type: "GET_USER_LIST_AGENT_SUCCESS", data });

          // this.props.history.push("/dashboard");
        }
      }
    } catch (error) {
      //console.log(error)
    }

    store.dispatch({ type: "LOADER_HIDE" });
  });
}

export function* updateUserStatus() {
  yield takeEvery("UPDATE_USER_STATUS", function*(action) {
    //console.log('start update user states')

    store.dispatch({ type: "LOADER_SHOW" });

    const response = yield API.updateUserStatus(action.params);

    try {
      if (response) {
        let data = response.data;

        if (data.success) {
          // //console.log("update user states "+JSON.stringify(data))
          // this.props.history.push("/user");
          store.dispatch({ type: "GET_USER_LIST" });

          store.dispatch({ type: "SHOW_MESSAGE", msg_type: "UPDATE" });
        } else alert("Error:\n " + "failed to get data");
      }
    } catch (error) {
      //console.log(error)
    }

    store.dispatch({ type: "LOADER_HIDE" });
  });
}

export function* masterUpdateUserProfile() {
  yield takeEvery("MASTER_UPDATE_USER", function*(action) {
    //console.log('start MASTER_UPDATE_USER')

    store.dispatch({ type: "LOADER_SHOW" });

    const response = yield API.masterUpdateUserProfile(action.params);

    try {
      if (response) {
        let data = response.data;

        if (data.success) {
          // //console.log("update user states "+JSON.stringify(data))
          // this.props.history.push("/user");
          store.dispatch({ type: "GET_USER_LIST" });

          store.dispatch({ type: "SHOW_MESSAGE", msg_type: "UPDATE" });
        } else alert("Error:\n " + "failed to get data");
      }
    } catch (error) {
      //console.log(error)
    }

    store.dispatch({ type: "LOADER_HIDE" });
  });
}

export function* createUser() {
  yield takeEvery("CREATE_USER", function*(action) {
    //console.log('start CREATE_USER')

    store.dispatch({ type: "LOADER_SHOW" });

    const response = yield API.createUser(action.params);

    try {
      if (response) {
        let data = response.data;

        if (data.success) {
          // //console.log("update user states "+JSON.stringify(data))
          // this.props.history.push("/user");
          store.dispatch({ type: "GET_USER_LIST" });

          store.dispatch({ type: "SHOW_MESSAGE", msg_type: "CREATE" });
        } else alert("Error:\n " + "failed to get data");
      }
    } catch (error) {
      //console.log(error)
    }

    store.dispatch({ type: "LOADER_HIDE" });
  });
}

export function* createUserAgent() {
  yield takeEvery("CREATE_USER_AGENT", function*(action) {
    //console.log('start CREATE_USER')

    store.dispatch({ type: "LOADER_SHOW" });

    const response = yield API.createUserAgent(action.params);

    try {
      if (response) {
        let data = response.data;

        if (data.success) {
          // //console.log("update user states "+JSON.stringify(data))
          // this.props.history.push("/user");
          store.dispatch({ type: "GET_USER_LIST_AGENT" });

          store.dispatch({ type: "SHOW_MESSAGE", msg_type: "CREATE" });
        } else alert("Error:\n " + "failed to get data");
      }
    } catch (error) {
      //console.log(error)
    }

    store.dispatch({ type: "LOADER_HIDE" });
  });
}

export function* resetUserPassword() {
  yield takeEvery("RESET_USER_PASSWORD", function*(action) {
    // console.log('start get RESET_USER_PASSWORD')

    store.dispatch({ type: "LOADER_SHOW" });

    try {
      const response = yield API.resetUserPassword(action.params);
      //console.log('show response ' + JSON.stringify(response))
      if (response) {
        let data = response.data;

        if (!data.success) alert("Error:\n " + "failed to get data");
        else {
          // //console.log("get user data "+JSON.stringify(data))
          //yield put({ type: 'RESET_USER_PASSWORD_SUCCESS', data })

          // this.props.history.push("/dashboard");

          store.dispatch({ type: "SHOW_MESSAGE", msg_type: "RESET_PASSWORD" });
        }
      }
    } catch (error) {
      //console.log(error)
    }

    store.dispatch({ type: "LOADER_HIDE" });
  });
}

export function* createGalaxyAccount() {
  yield takeEvery("CREATE_GALAXY_ACCOUNT", function*(action) {
    //console.log('start CREATE_USER')

    store.dispatch({ type: "LOADER_SHOW" });

    const response = yield API.createGalaxyAccount(action.params);

    try {
      if (response) {
        let data = response.data;

        if (data.success) {
          // //console.log("update user states "+JSON.stringify(data))
          // this.props.history.push("/user");
          store.dispatch({ type: "GET_USER_LIST" });

          store.dispatch({ type: "SHOW_MESSAGE", msg_type: "CREATE" });
        } else alert("Error:\n " + "failed to get data");
      }
    } catch (error) {
      //console.log(error)
    }

    store.dispatch({ type: "LOADER_HIDE" });
  });
}

export default function* user_saga() {
  yield all([
    fork(getUserList),
    fork(updateUserStatus),
    fork(createUser),
    fork(resetUserPassword),
    fork(getUserListAgent),
    fork(createUserAgent),
    fork(masterUpdateUserProfile),
    fork(getUserExport),
    fork(createGalaxyAccount)
  ]);
}
