// import axios from "axios";
import instance from "../index";

// import {postRequest} from "../../helpers/AxiosHelper";

// Base Path
export const basePath = () => {
  return "/api/admin";
};


export const loginAdmin = (params) => {
  const url = `${basePath()}/login`;
 
  params = {
    "username": params.username,
    "password": params.password
  }

  //return postRequest(url,params);

  return instance.post(url,params)
};
