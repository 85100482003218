import { put, takeEvery, all, fork } from 'redux-saga/effects'

import { store } from '../index'
import * as API from '../../api'

export function* getDrawTimeList() {
    yield takeEvery('GET_DRAW_TIME_LIST', function*(action) {
        // console.log('start GET_DRAW_TIME_LIST')

        store.dispatch({ type: 'LOADER_SHOW' })

        const response = yield API.getDrawTimeList()
        try {
            if (response) {
                let data = response.data

                if (!data.success) alert('Error:\n ' + 'failed to get data')
                else {
                    // console.log('return saga getDrawList')
                    // //console.log("get portal activity "+JSON.stringify(data))
                    yield put({ type: 'GET_DRAW_TIME_LIST_SUCCESS', data })

                    // this.props.history.push("/dashboard");
                }
            }
        } catch (error) {
            console.log(error)
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })
}

export function* updateDrawTime() {
    yield takeEvery('UPDATE_DRAW_TIME', function*(action) {
        yield put({ type: 'LOADER_SHOW' })
        //get params
        let params = action.params

        const response = yield API.updateDrawTime(params)
        try {
            //  //console.log("show response "+JSON.stringify(response))
            if (response) {
                let data = response.data

                if (data.success) {
                    // //console.log("update wallet activity "+JSON.stringify(data))
                    // this.props.history.push("/dashboard");
                    yield put({ type: 'SHOW_MESSAGE', msg_type: 'UPDATE' })
                    yield put({ type: 'GET_DRAW_TIME_LIST' })
                } else alert('Error:\n ' + 'failed to get data')
            }
        } catch (error) {
            console.log(error)
        }

        yield put({ type: 'LOADER_HIDE' })
    })
}

export default function* draw_time_saga() {
    yield all([fork(getDrawTimeList), fork(updateDrawTime)])
}
