// 考虑到网站可能有好几个域名，所以单独提出来

// export const API = 'http://rap2api.taobao.org/app/mock/234047'

// export const URLAPI = ''
// Live: 'https://apiuq4d.drsoft.cloud',
// Live: 'https://api.uq4d.comm',
const apiHost = {
  Live: "https://api.luckyspins33.com",
  Stage: "https://apilbasia.drsoft.cloud",
  Dev: "http://localhost:8889"
};

const hostUrl = () => {
  return apiHost["Live"];
};

const clientHostUrl = () => {
  return "https://admin.luckyspins33.com";
};

export { hostUrl, clientHostUrl };
