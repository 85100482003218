import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { Provider } from 'react-redux'
import {store,persistor} from './store'
import {PersistGate} from 'redux-persist/lib/integration/react';


const AppView = (
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <App />
        </PersistGate>
    </Provider>
)

ReactDOM.render(AppView, document.getElementById('root'))
