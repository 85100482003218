import { put, takeEvery, all, fork } from 'redux-saga/effects'

import { store } from '../index'
import * as API from '../../api'

export function* getGoldRateList() {
    yield takeEvery('GET_GOLD_RATE_LIST', function*(action) {
        //console.log('start get GET_WHEEL_LIST')

        store.dispatch({ type: 'LOADER_SHOW' })
        const response = yield API.getGoldRateList()
        try {
            if (response) {
                let data = response.data

                if (data.success) {
                    //console.log('get wallet activity ' + JSON.stringify(data))
                    yield put({ type: 'GET_GOLD_RATE_LIST_SUCCESS', data })

                    // this.props.history.push("/dashboard");
                } else alert('Error:\n ' + 'failed to get data')
            }
        } catch (error) {
            //console.log(error)
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })
}


export function* getGoldSilverList() {
    yield takeEvery('GET_SILVER_RATE_LIST', function*(action) {
        //console.log('start get GET_WHEEL_LIST')

        store.dispatch({ type: 'LOADER_SHOW' })
        const response = yield API.getGoldSilverList()
        try {
            if (response) {
                let data = response.data

                if (data.success) {
                    //console.log('get wallet activity ' + JSON.stringify(data))
                    yield put({ type: 'GET_SILVER_RATE_LIST_SUCCESS', data })

                    // this.props.history.push("/dashboard");
                } else alert('Error:\n ' + 'failed to get data')
            }
        } catch (error) {
            //console.log(error)
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })
}




export function* updateGoldRate() {
    yield takeEvery('UPDATE_GOLD_RATE', function*(action) {
        store.dispatch({ type: 'LOADER_SHOW' })

        //get params
        let params = action.params

        const response = yield API.updateGoldRate(params)
        try {
            //  //console.log("show response "+JSON.stringify(response))
            if (response) {
                let data = response.data

                if (data.success) {
                    // //console.log("update wallet activity "+JSON.stringify(data))
                    // this.props.history.push("/dashboard");
                    yield put({ type: 'SHOW_MESSAGE', msg_type: 'SUBMIT' })
                    yield put({ type: 'GET_GOLD_RATE_LIST' })
                } else alert('Error:\n ' + 'failed to get data')
            }
        } catch (error) {
            //console.log(error)
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })
}

export function* updateSilverRate() {
    yield takeEvery('UPDATE_SILVER_RATE', function*(action) {
        store.dispatch({ type: 'LOADER_SHOW' })

        //get params
        let params = action.params

        const response = yield API.updateSilverRate(params)
        try {
            //  //console.log("show response "+JSON.stringify(response))
            if (response) {
                let data = response.data

                if (data.success) {
                    // //console.log("update wallet activity "+JSON.stringify(data))
                    // this.props.history.push("/dashboard");
                    yield put({ type: 'SHOW_MESSAGE', msg_type: 'SUBMIT' })
                    yield put({ type: 'GET_SILVER_RATE_LIST' })
                } else alert('Error:\n ' + 'failed to get data')
            }
        } catch (error) {
            //console.log(error)
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })
}

export function* getTokenList() {
    yield takeEvery('GET_TOKEN_LIST', function*(action) {
     

        store.dispatch({ type: 'LOADER_SHOW' })

        let page = action.page

        const response = yield API.getTokenList(page, action.params)
        try {
            if (response) {
                let data = response.data

                if (data.success) {
                    yield put({ type: 'GET_TOKEN_LIST_SUCCESS', data })

                    // this.props.history.push("/dashboard");
                } else alert('Error:\n ' + 'failed to get data')
            }
        } catch (error) {
            //console.log(error)
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })
}


export function* getTokenListExport() {
    yield takeEvery('GET_TOKEN_LIST_EXPORT', function*(action) {
     

        store.dispatch({ type: 'LOADER_SHOW' })

        let page = action.page

        const response = yield API.getTokenListExport(action.params)
        try {
            if (response) {
                let data = response.data

                if (data.success) {
                    yield put({ type: 'GET_TOKEN_LIST_EXPORT_SUCCESS', data })

                    // this.props.history.push("/dashboard");
                } else alert('Error:\n ' + 'failed to get data')
            }
        } catch (error) {
            //console.log(error)
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })
}

export function* getTokenAdminList() {
    yield takeEvery('GET_ADMIN_TOKEN_LIST', function*(action) {
     

        store.dispatch({ type: 'LOADER_SHOW' })

        let page = action.page

        const response = yield API.getTokenAdminList(page, action.params)
        try {
            if (response) {
                let data = response.data

                if (data.success) {
                    yield put({ type: 'GET_ADMIN_TOKEN_LIST_SUCCESS', data })

                    // this.props.history.push("/dashboard");
                } else alert('Error:\n ' + 'failed to get data')
            }
        } catch (error) {
            //console.log(error)
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })
}


export function* getTokenAdminListExport() {
    yield takeEvery('GET_ADMIN_TOKEN_LIST_EXPORT', function*(action) {
     

        store.dispatch({ type: 'LOADER_SHOW' })

        let page = action.page

        const response = yield API.getTokenAdminListExport(action.params)
        try {
            if (response) {
                let data = response.data

                if (data.success) {
                    yield put({ type: 'GET_ADMIN_TOKEN_LIST_EXPORT_SUCCESS', data })

                    // this.props.history.push("/dashboard");
                } else alert('Error:\n ' + 'failed to get data')
            }
        } catch (error) {
            //console.log(error)
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })
}

export function* createToken() {
    yield takeEvery('CREATE_TOKEN', function*(action) {
        store.dispatch({ type: 'LOADER_SHOW' })

        //get params
        let params = action.params
       
        const response = yield API.createToken(params)
        try {
            //  //console.log("show response "+JSON.stringify(response))
            if (response) {
                let data = response.data

                if (data.success) {
                    // //console.log("update wallet activity "+JSON.stringify(data))
                    // this.props.history.push("/dashboard");
                    yield put({ type: 'SHOW_MESSAGE', msg_type: 'SUBMIT' })
                    //yield put({ type: 'GET_REWARD_LIST' })
                } else alert('Error:\n ' + 'failed to get data')
            }
        } catch (error) {
            //console.log(error)
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })
}

export function* getRewardList() {
    yield takeEvery('GET_REWARD_LIST', function*(action) {

        store.dispatch({ type: 'LOADER_SHOW' })

        let page = action.page

        const response = yield API.getRewardList(page, action.params)
        try {
            if (response) {
                let data = response.data

                if (data.success) {
                    //console.log('get wallet activity ' + JSON.stringify(data))
                    yield put({ type: 'GET_REWARD_LIST_SUCCESS', data })

                    // this.props.history.push("/dashboard");
                } else alert('Error:\n ' + 'failed to get data')
            }
        } catch (error) {
            //console.log(error)
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })
}

export function* updateGoldReward() {
    yield takeEvery('UPDATE_GOLD_REWARD', function*(action) {
        store.dispatch({ type: 'LOADER_SHOW' })

        //get params
        let params = action.params
       
        const response = yield API.updateGoldReward(params)
        try {
            //  //console.log("show response "+JSON.stringify(response))
            if (response) {
                let data = response.data

                if (data.success) {
                    // //console.log("update wallet activity "+JSON.stringify(data))
                    // this.props.history.push("/dashboard");
                    yield put({ type: 'SHOW_MESSAGE', msg_type: 'SUBMIT' })
                    yield put({ type: 'GET_REWARD_LIST' })
                } else alert('Error:\n ' + 'failed to get data')
            }
        } catch (error) {
            //console.log(error)
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })
}

export function* updateSilverReward() {
    yield takeEvery('UPDATE_SILVER_REWARD', function*(action) {
        store.dispatch({ type: 'LOADER_SHOW' })

        //get params
        let params = action.params

        const response = yield API.updateSilverReward(params)
        try {
            //  //console.log("show response "+JSON.stringify(response))
            if (response) {
                let data = response.data

                if (data.success) {
                    // //console.log("update wallet activity "+JSON.stringify(data))
                    // this.props.history.push("/dashboard");
                    yield put({ type: 'SHOW_MESSAGE', msg_type: 'SUBMIT' })
                    yield put({ type: 'GET_REWARD_LIST' })
                } else alert('Error:\n ' + 'failed to get data')
            }
        } catch (error) {
            //console.log(error)
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })
}
export default function* wheel_saga() {
    yield all([
        fork(getGoldRateList),
        fork(getGoldSilverList),
        fork(updateGoldRate),
        fork(updateSilverRate),
        fork(getTokenList),
        fork(getTokenListExport),
        fork(getTokenAdminList),
        fork(getTokenAdminListExport),
        fork(createToken),
        fork(getRewardList),
        fork(updateGoldReward),
        fork(updateSilverReward)
    ])
}
