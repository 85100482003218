import instance from '../index'

// Base Path
export const basePath = () => {
    return '/api/drawtime'
}

export const getDrawTimeList = () => {
    const url = `${basePath()}/byadmin`

    return instance.get(url)
}



export const updateDrawTime = params => {
    const url = `${basePath()}/update`

    return instance.post(url, params)
}

