import { put, takeEvery, all, fork } from 'redux-saga/effects'

import { store } from '../index'
import * as API from '../../api'

export function* PortalList() {
    yield takeEvery('GET_PORTAL_LIST', function*(action) {
        // console.log('start get portal activity')

        store.dispatch({ type: 'LOADER_SHOW' })
        const response = yield API.getPortalList()
        try {
            if (response) {
                let data = response.data

                if (!data.success) alert('Error:\n ' + 'failed to get data')
                else {
                    yield put({ type: 'GET_PORTAL_LIST_SUCCESS', data })
                }
            }
        } catch (error) {
            // console.log(error)
        }
        store.dispatch({ type: 'LOADER_HIDE' })
    })

    yield takeEvery('UPDATE_PORTAL_LIST', function*(action) {
        // //console.log("start get portal activity")

        store.dispatch({ type: 'LOADER_SHOW' })

        try {
            const response = yield API.updatePortal(action.params)
            if (response) {
                let data = response.data

                if (!data.success) alert('Error:\n ' + 'failed to get data')
                else {
                    yield put({ type: 'SHOW_MESSAGE', msg_type: 'UPDATE' })
                }
            }
        } catch (error) {
            //console.log(error)
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })
}

export default function* portal_saga() {
    yield all([fork(PortalList)])
}
