import { createStore, applyMiddleware, combineReducers } from 'redux'
import reducer from './reducer/reducer'

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from 'redux-saga'
import sagas from './saga'
import { browserHistory } from 'react-router'

import { routerReducer, routerMiddleware } from 'react-router-redux'
import { createHashHistory as createHistory } from 'history' //const store = createStore(reducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())

import thunk from 'redux-thunk'

import loginReducer from './reducer/login_reducer'
import reportReducer from './reducer/report_reducer'
import userReducer from './reducer/user_reducer'
import adminReducer from './reducer/admin_reducer'

import walletReducer from './reducer/wallet_reducer'
import portalReducer from './reducer/portal_reducer'
import hotReducer from './reducer/hot_reducer'
import bankAccountReducer from './reducer/bank_account_reducer'
import availableDaysReducer from './reducer/available_days_reducer'
import userPermissionReducer from './reducer/user_permission_reducer'
import promoReducer from './reducer/promo_reducer'
import drawReducer from './reducer/draw_reducer'
import drawTimeReducer from './reducer/draw_time_reducer'
import drawModeReducer from './reducer/draw_mode_reducer'

import wheelReducer from './reducer/wheel_reducer'

import loaderReducer from './reducer/loader_reducer'

const history = createHistory()

const routeMiddleware = routerMiddleware(history)

const persistConfig = {
    key: 'auth',
    storage: storage,
    whitelist: ['login']
}

const reducers = combineReducers({
    reducer: reducer,
    loader: loaderReducer,
    login: loginReducer,
    user: userReducer,
    wallet: walletReducer,
    portal: portalReducer,
    hot: hotReducer,
    bankAccount: bankAccountReducer,
    report: reportReducer,
    availableDays: availableDaysReducer,
    userPermission: userPermissionReducer,
    promo: promoReducer,
    admin: adminReducer,
    draw: drawReducer,
    drawTime: drawTimeReducer,
    mode: drawModeReducer,
    wheel:wheelReducer,
    routerReducer
})

//config saga
const sagaMiddleware = createSagaMiddleware()

const persistedReducer = persistReducer(persistConfig, reducers)

//let middlewares = [thunk,sagaMiddleware, routeMiddleware];
let middlewares = [thunk, sagaMiddleware, routeMiddleware]

const store = createStore(persistedReducer, undefined, applyMiddleware(...middlewares))

sagaMiddleware.run(sagas)

const persistor = persistStore(store)

//export default store

export { persistor, store, history }
