const initialState = {
    hotList: []
}

//trigger action
const hot = (state = initialState, action) => {
    const newState = { ...state }

    switch (action.type) {
        case 'GET_HOT_LIST':
            break
        case 'GET_HOT_LIST_SUCCESS':
            newState.hotList = action.data.list
            // newState.token = action.data.token
            break
        case 'UPDATE_HOT_NUMBER':
            // console.log('here UPDATE_HOT_NUMBER')
            // newState.token = action.data.token
            break

        default:
            return state
    }

    return newState
}

export default hot
