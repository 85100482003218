import Moment from 'moment'

const initialState = {
    visible: true,
    message: null
}

//trigger action
const login = (state = initialState, action) => {
    const newState = { ...state }
    // //console.log("initial "+action.type)
    // //console.log("REHYDRATE "+JSON.stringify(state))

    switch (action.type) {
        case 'LOADER_SHOW':
            newState.visible = true
            break
        case 'LOADER_HIDE':
            newState.visible = false
            break
        case 'SHOW_MESSAGE':
            let text = ''

            switch (action.msg_type) {
                case 'CREATE':
                    text = 'Create Successful'
                    break
                case 'UPDATE':
                    text = 'Update Successful'
                    break
                case 'DELETE':
                    text = 'Delete Successful'
                    break
                case 'SUBMIT':
                    text = 'Submit Successful'
                    break
                case 'REJECT':
                    text = 'Reject Successful'
                    break
                case 'RESET_PASSWORD':
                    text = 'Reset Password Successful'
                    break
                case 'CHANGE_PASSWORD':
                    text = 'Change Password Successful'
                    break
            }

            newState.message = {
                text: text,
                time: Moment()
            }
            break
    }

    return newState
}

export default login
