const initialState = {
    wheelList: [],
    wheelExportList: [],
    wheelListPage: 1
}

//trigger action
const user = (state = initialState, action) => {
    const newState = { ...state }

    switch (action.type) {
        case 'GET_GOLD_RATE_LIST':
            break
        case 'GET_GOLD_RATE_LIST_SUCCESS':
            newState.wheelList = action.data.list
            newState.wheelListPage = action.data.total_page
            break
        case 'GET_SILVER_RATE_LIST':
            break
        case 'GET_SILVER_RATE_LIST_SUCCESS':
            newState.wheelList = action.data.list
            newState.wheelListPage = action.data.total_page
            break
        case 'UPDATE_GOLD_RATE':
            break  
        case 'UPDATE_SILVER_RATE':
            break
        case 'GET_TOKEN_LIST':
            break
        case 'GET_TOKEN_LIST_SUCCESS':
            newState.wheelList = action.data.list
            newState.wheelListPage = action.data.total_page
            break
        case 'GET_TOKEN_LIST_EXPORT':
            break
        case 'GET_TOKEN_LIST_EXPORT_SUCCESS':
            newState.wheelExportList = action.data.list
            break
        case 'GET_ADMIN_TOKEN_LIST':
            break
        case 'GET_ADMIN_TOKEN_LIST_SUCCESS':
            newState.wheelList = action.data.list
            newState.wheelListPage = action.data.total_page
            break
        case 'GET_ADMIN_TOKEN_LIST_EXPORT':
            break
        case 'GET_ADMIN_TOKEN_LIST_EXPORT_SUCCESS':
            newState.wheelExportList = action.data.list
            break
        case 'CREATE_TOKEN':
            break
        case 'GET_REWARD_LIST':
            break
        case 'GET_REWARD_LIST_SUCCESS':
            newState.wheelList = action.data.list
            newState.wheelListPage = action.data.total_page
            break
        case 'UPDATE_GOLD_REWARD':
            break  
        case 'UPDATE_SILVER_REWARD':
            break
        default:
            return state
    }

    return newState
}

export default user
