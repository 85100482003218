import instance from "../index";

// Base Path
export const basePath = () => {
  return "/api/portal";
};


export const getPortalList = () => {
  const url = `${basePath()}`;
 
  return instance.get(url);
};


export const updatePortal = (params) => {
  const url = `${basePath()}/update`;

//   {
//   "id": "1",
//   "limit" : "100"
// }


  return instance.post(url,params);
};