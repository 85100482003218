import { put, takeEvery, all, fork } from 'redux-saga/effects'
//import {SetAxiosDefaultConfig} from "../../helpers/AxiosHelper";
import { store } from '../index'
import { push } from 'react-router-redux'
import * as API from '../../api'

//when trigger action will find
export function* loginAdmin() {
    yield takeEvery('LOGIN_ADMIN', function*(action) {
        // //console.log("start login admin")

        //console.log(action)
        store.dispatch({ type: 'LOADER_SHOW' })

        let params = action.params

        const response = yield API.loginAdmin(params)

        //  //console.log("show response "+JSON.stringify(response))

        if (response) {
            let data = response.data

            if (!data.success) alert('Error:\n ' + 'login failed')
            else {
                yield put({ type: 'LOGIN_SUCCESS', data })

                yield API.SetAxiosDefaultJWT(data.token)
                // this.props.history.push("/dashboard");
                yield put(push('/dashboard'))
            }
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })
}

export function* logout() {
    yield takeEvery('LOGOUT_ADMIN', function*(action) {
        // //console.log("logout admin")
        store.dispatch({ type: 'LOADER_SHOW' })

        try {
            yield put(push('/login'))
        } catch (error) {
            //console.log(error)
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })
}

export default function* login_saga() {
    yield all([fork(loginAdmin), fork(logout)])
}
