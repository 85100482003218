// import { store } from "../store";
// import {SetAxiosAuthBearer} from "../../helpers/AxiosHelper";
import { SetAxiosDefaultJWT } from '../../api'

const initialState = {
    already_login: false,
    token: null,
    refreshToken: false
}

//trigger action
const login = (state = initialState, action) => {
    const newState = { ...state }
    // //console.log("initial "+action.type)
    // //console.log("REHYDRATE "+JSON.stringify(state))

    switch (action.type) {
        case 'persist/REHYDRATE':
            if (action.payload) {
                //check persist local storage
                newState.already_login = action.payload.login.already_login
                newState.token = action.payload.login.token
                newState.refreshToken = action.payload.login.refreshToken || null
                //find token and set axios

                SetAxiosDefaultJWT(action.payload.login.token)
            }

        case 'LOGIN_ADMIN':
            //newState.age += action.value;

            break
        case 'LOGIN_SUCCESS':
            newState.already_login = true
            newState.token = action.data.token
            newState.refreshToken = action.data.refreshtoken
            break
        // case "LOGIN_UNAUTHORIZED":
        //   //console.log("token unauthorized")
        //   newState.already_login = false
        //   newState.token = null
        //   break;
        case 'LOGOUT_ADMIN':
            return initialState

        case 'REFRESH_TOKEN':
            newState.token = action.refreshToken
        //break;
    }

    return newState
}

export default login
