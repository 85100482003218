import { put, takeEvery, all, fork } from 'redux-saga/effects'

import * as API from '../../api'
import { store } from '../index'

export function* getPromoProcess() {
    yield takeEvery('GET_ANNOUCEMENT_LIST', function*(action) {
        // console.log('start get GET_ANNOUCEMENT_LIST')

        store.dispatch({ type: 'LOADER_SHOW' })
        const response = yield API.getAnnoucementList(action.page)

        // //console.log("show response "+JSON.stringify(response))
        try {
            if (response) {
                let data = response.data

                if (data.success) {
                    yield put({ type: 'GET_ANNOUCEMENT_LIST_SUCCESS', data })

                    // this.props.history.push("/dashboard");
                } else alert('Error:\n ' + 'failed to get data')
            }
        } catch (error) {
            // console.log(error)
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })

    yield takeEvery('CREATE_ANNOUCEMENT', function*(action) {
        // console.log('start get CREATE_ANNOUCEMENT')

        store.dispatch({ type: 'LOADER_SHOW' })

        let params = action.params

        const response = yield API.createAnnoucement(params)

        // //console.log("show response "+JSON.stringify(response))
        try {
            if (response) {
                let data = response.data

                if (data.success) {
                    yield put({ type: 'SHOW_MESSAGE', msg_type: 'CREATE' })
                    yield put({ type: 'GET_ANNOUCEMENT_LIST' })

                    // this.props.history.push("/dashboard");
                } else alert('Error:\n ' + 'failed to get data')
            }
        } catch (error) {
            //console.log(error)
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })

    yield takeEvery('UPDATE_ANNOUCEMENT', function*(action) {
        // console.log('start get UPDATE_ANNOUCEMENT')

        store.dispatch({ type: 'LOADER_SHOW' })

        let params = action.params

        const response = yield API.updateAnnoucement(params)

        // //console.log("show response "+JSON.stringify(response))
        try {
            if (response) {
                let data = response.data

                if (data.success) {
                    yield put({ type: 'SHOW_MESSAGE', msg_type: 'UPDATE' })
                    yield put({ type: 'GET_ANNOUCEMENT_LIST' })

                    // this.props.history.push("/dashboard");
                } else alert('Error:\n ' + 'failed to get data')
            }
        } catch (error) {
            //console.log(error)
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })

    yield takeEvery('DELETE_ANNOUCEMENT', function*(action) {
        // console.log('start get DELETE_ANNOUCEMENT')

        store.dispatch({ type: 'LOADER_SHOW' })

        let params = action.params

        const response = yield API.deleteAnnoucement(params)

        // //console.log("show response "+JSON.stringify(response))
        try {
            if (response) {
                let data = response.data

                if (data.success) {
                    yield put({ type: 'SHOW_MESSAGE', msg_type: 'DELETE' })
                    yield put({ type: 'GET_ANNOUCEMENT_LIST' })

                    // this.props.history.push("/dashboard");
                } else alert('Error:\n ' + 'failed to get data')
            }
        } catch (error) {
            //console.log(error)
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })

    yield takeEvery('GET_TELEGRAM', function*(action) {
        // console.log('start get GET_TELEGRAM')

        store.dispatch({ type: 'LOADER_SHOW' })
        const response = yield API.getTelegramList(action.page)

        // //console.log("show response "+JSON.stringify(response))
        try {
            if (response) {
                let data = response.data

                if (data.success) {
                    yield put({ type: 'GET_TELEGRAM_SUCCESS', data })

                    // this.props.history.push("/dashboard");
                } else alert('Error:\n ' + 'failed to get data')
            }
        } catch (error) {
            //console.log(error)
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })

    yield takeEvery('CREATE_TELEGRAM', function*(action) {
        // console.log('start get CREATE_TELEGRAM')

        store.dispatch({ type: 'LOADER_SHOW' })

        let params = action.params

        const response = yield API.createTelegram(params)

        // //console.log("show response "+JSON.stringify(response))
        try {
            if (response) {
                let data = response.data

                if (data.success) {
                    yield put({ type: 'SHOW_MESSAGE', msg_type: 'CREATE' })
                    yield put({ type: 'GET_TELEGRAM' })

                    // this.props.history.push("/dashboard");
                } else alert('Error:\n ' + 'failed to get data')
            }
        } catch (error) {
            //console.log(error)
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })

    yield takeEvery('UPDATE_TELEGRAM', function*(action) {
        // console.log('start get UPDATE_TELEGRAM')

        store.dispatch({ type: 'LOADER_SHOW' })

        let params = action.params

        const response = yield API.updateTelegram(params)

        // //console.log("show response "+JSON.stringify(response))
        try {
            if (response) {
                let data = response.data

                if (data.success) {
                    yield put({ type: 'SHOW_MESSAGE', msg_type: 'UPDATE' })
                    yield put({ type: 'GET_TELEGRAM' })

                    // this.props.history.push("/dashboard");
                } else alert('Error:\n ' + 'failed to get data')
            }
        } catch (error) {
            //console.log(error)
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })

    yield takeEvery('DELETE_TELEGRAM', function*(action) {
        // console.log('start get DELETE_TELEGRAM')

        store.dispatch({ type: 'LOADER_SHOW' })

        let params = action.params

        const response = yield API.deleteTelegram(params)

        // //console.log("show response "+JSON.stringify(response))
        try {
            if (response) {
                let data = response.data

                if (data.success) {
                    yield put({ type: 'SHOW_MESSAGE', msg_type: 'DELETE' })
                    yield put({ type: 'GET_TELEGRAM' })

                    // this.props.history.push("/dashboard");
                } else alert('Error:\n ' + 'failed to get data')
            }
        } catch (error) {
            //console.log(error)
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })

    yield takeEvery('GET_WHATSAPPS', function*(action) {
        // console.log('start get GET_TELEGRAM')

        store.dispatch({ type: 'LOADER_SHOW' })
        const response = yield API.getWhatsappsList(action.page)

        // //console.log("show response "+JSON.stringify(response))
        try {
            if (response) {
                let data = response.data

                if (data.success) {
                    yield put({ type: 'GET_WHATSAPPS_SUCCESS', data })

                    // this.props.history.push("/dashboard");
                } else alert('Error:\n ' + 'failed to get data')
            }
        } catch (error) {
            //console.log(error)
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })

    yield takeEvery('CREATE_WHATSAPPS', function*(action) {
        // console.log('start get CREATE_TELEGRAM')

        store.dispatch({ type: 'LOADER_SHOW' })

        let params = action.params

        const response = yield API.createWhatsapps(params)

        // //console.log("show response "+JSON.stringify(response))
        try {
            if (response) {
                let data = response.data

                if (data.success) {
                    yield put({ type: 'SHOW_MESSAGE', msg_type: 'CREATE' })
                    yield put({ type: 'GET_WHATSAPPS' })

                    // this.props.history.push("/dashboard");
                } else alert('Error:\n ' + 'failed to get data')
            }
        } catch (error) {
            //console.log(error)
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })

    yield takeEvery('UPDATE_WHATSAPPS', function*(action) {
        // console.log('start get UPDATE_TELEGRAM')

        store.dispatch({ type: 'LOADER_SHOW' })

        let params = action.params

        const response = yield API.updateWhatsapps(params)

        // //console.log("show response "+JSON.stringify(response))
        try {
            if (response) {
                let data = response.data

                if (data.success) {
                    yield put({ type: 'SHOW_MESSAGE', msg_type: 'UPDATE' })
                    yield put({ type: 'GET_WHATSAPPS' })

                    // this.props.history.push("/dashboard");
                } else alert('Error:\n ' + 'failed to get data')
            }
        } catch (error) {
            //console.log(error)
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })

    yield takeEvery('DELETE_WHATSAPPS', function*(action) {
        // console.log('start get DELETE_TELEGRAM')

        store.dispatch({ type: 'LOADER_SHOW' })

        let params = action.params

        const response = yield API.deleteWhatsapps(params)

        // //console.log("show response "+JSON.stringify(response))
        try {
            if (response) {
                let data = response.data

                if (data.success) {
                    yield put({ type: 'SHOW_MESSAGE', msg_type: 'DELETE' })
                    yield put({ type: 'GET_WHATSAPPS' })

                    // this.props.history.push("/dashboard");
                } else alert('Error:\n ' + 'failed to get data')
            }
        } catch (error) {
            //console.log(error)
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })

    yield takeEvery('CREATE_BANNER', function*(action) {
        // console.log('start get CREATE_BANNER')

        store.dispatch({ type: 'LOADER_SHOW' })

        // //console.log("show response "+JSON.stringify(response))
        try {
            //upload banner
            const uploadBanner = yield API.uploadBanner(action.file)

            if (uploadBanner) {
                let data = uploadBanner.data

                if (data.success) {
                    let filePath = data.file[0].value
                    if (filePath) {
                        const response = yield API.createBanner(filePath)

                        if (response)
                            if (data.success) {
                                yield put({ type: 'SHOW_MESSAGE', msg_type: 'CREATE' })
                                yield put({ type: 'GET_BANNER' })
                            }
                    }

                    // this.props.history.push("/dashboard");
                } else alert('Error:\n ' + 'failed to get data')
            }
        } catch (error) {
            //console.log(error)
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })

    yield takeEvery('GET_BANNER', function*(action) {
        // console.log('start get GET_BANNER')

        store.dispatch({ type: 'LOADER_SHOW' })
        const response = yield API.getBannerList(action.page)

        // //console.log("show response "+JSON.stringify(response))
        try {
            if (response) {
                let data = response.data

                if (data.success) {
                    yield put({ type: 'GET_BANNER_SUCCESS', data })

                    // this.props.history.push("/dashboard");
                } else alert('Error:\n ' + 'failed to get data')
            }
        } catch (error) {
            //console.log(error)
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })

    yield takeEvery('UPDATE_BANNER', function*(action) {
        // console.log('start get UPDATE_BANNER')

        store.dispatch({ type: 'LOADER_SHOW' })

        let params = action.params

        const response = yield API.updateBanner(params)

        // //console.log("show response "+JSON.stringify(response))
        try {
            if (response) {
                let data = response.data

                if (data.success) {
                    yield put({ type: 'SHOW_MESSAGE', msg_type: 'UPDATE' })
                    yield put({ type: 'GET_BANNER' })

                    // this.props.history.push("/dashboard");
                } else alert('Error:\n ' + 'failed to get data')
            }
        } catch (error) {
            //console.log(error)
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })

    yield takeEvery('DELETE_BANNER', function*(action) {
        // console.log('start get DELETE_BANNER')

        store.dispatch({ type: 'LOADER_SHOW' })

        let params = action.params

        const response = yield API.deleteBanner(params)

        // //console.log("show response "+JSON.stringify(response))
        try {
            if (response) {
                let data = response.data

                if (data.success) {
                    yield put({ type: 'SHOW_MESSAGE', msg_type: 'DELETE' })
                    yield put({ type: 'GET_BANNER' })

                    // this.props.history.push("/dashboard");
                } else alert('Error:\n ' + 'failed to get data')
            }
        } catch (error) {
            //console.log(error)
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })
}

export default function* promo_saga() {
    yield all([fork(getPromoProcess)])
}
