import { put, takeEvery, all, fork } from 'redux-saga/effects'

import { store } from '../index'
import * as API from '../../api'

export function* listen_available_days() {
    yield takeEvery('AVAILABLE_DAYS', function*(action) {
        // console.log('start get AVAILABLE_DAYSy')

        store.dispatch({ type: 'LOADER_SHOW' })
        const response = yield API.getAvailableDays()

        try {
            if (response) {
                let data = response.data

                if (data.success) {
                    // console.log('get AVAILABLE_DAYS_SUCCESS activity ' + JSON.stringify(data))
                    yield put({ type: 'AVAILABLE_DAYS_SUCCESS', data })

                    // this.props.history.push("/dashboard");
                } else alert('Error:\n ' + 'failed to get data')
            }
        } catch (error) {
            console.log(error)
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })
}

export default function* available_days_saga() {
    yield all([fork(listen_available_days)])
}
