
const initialState = {
  availableDaysList: []
};

//trigger action 
const availableDays = (state = initialState, action) => {
  const newState = { ...state };

  switch (action.type) {
    case "AVAILABLE_DAYS":
      break;
    case "AVAILABLE_DAYS_SUCCESS":
       newState.availableDaysList = action.data.list
      // newState.token = action.data.token
      break;
    case "UPDATE_AVAILABLE_DAYS":
      break;
    default:
      return state;
  }


  return newState;
};

export default availableDays;