import instance from '../index'

// Base Path
export const basePath = () => {
    return '/api/report'
}

//agent
export const getAgentDrawReport = () => {
    const url = `${basePath()}/byagent/draw`
    return instance.get(url)
}

export const getAgentYearlyReport = () => {
    const url = `${basePath()}/byagent/yearlyreport`
    return instance.get(url)
}

export const getAgentActiveUser = () => {
    const url = `${basePath()}/byagent/user`
    return instance.get(url)
}

//admin
export const getDrawReport = () => {
    const url = `${basePath()}/draw`

    return instance.get(url)
}

export const getYearlyReport = () => {
    const url = `${basePath()}/yearlyreport`
    return instance.get(url)
}

export const getActiveUser = () => {
    const url = `${basePath()}/user`

    return instance.get(url)
}

export const getAgentReport = () => {
    const url = `${basePath()}/downline`

    return instance.get(url)
}

export const getReport = (page = 1, data = {}) => {
    const url = `${basePath()}/filter/${page}`

    let req = {
        params: data
    }

    return instance.get(url, req)
}

export const getReportExport = (data = {}) => {
    const url = `${basePath()}/filter/export`

    let req = {
        params: data
    }

    return instance.get(url, req)
}

//master
export const getCommisionReport = (page = 1, data = {}) => {
    const url = `${basePath()}/agent/commission/${page}`

    let req = {
        params: data
    }

    return instance.get(url, req)
}

export const updateCommisionReport  = params => {
    const url = `${basePath()}/commission/update`

    return instance.post(url, params)
}

//agent own report
export const getAgentCommisionReport = (page = 1, data = {}) => {
    const url = `${basePath()}/own/commission/${page}`

    let req = {
        params: data
    }

    return instance.get(url, req)
}

export const getDailyReport = () => {
    const url = `${basePath()}/dailyreport`
    return instance.get(url)
}

export const getAgentDailyReport = () => {
    const url = `${basePath()}/byagent/dailyreport`
    return instance.get(url)
}