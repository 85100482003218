import instance from '../index'

// Base Path
export const basePath = () => {
    return '/api/wheel'
}

/*export const getWalletList = (page = 1, status = 0) => {
    // 0 = Pending
    // 1 = Approved
    // 2 = Reject

    const url = `${basePath()}/${status}/${page}`

    return instance.get(url)
}*/


export const getGoldRateList = () => {
    const url = `${basePath()}/rate/gold`

   
    return instance.get(url)
}

export const getGoldSilverList = () => {
    const url = `${basePath()}/rate/silver`

    return instance.get(url)
}

export const updateGoldRate = params => {
    const url = `${basePath()}/rate/gold/update`

    return instance.post(url, params)
}

export const updateSilverRate = params => {
    const url = `${basePath()}/rate/silver/update`

    return instance.post(url, params)
}


export const getTokenList = (page = 1, data = {}) => {

    let req = {
        params: data
    };

    const url = `${basePath()}/token/tokenlog/${page}`

    return instance.get(url, req)
}


export const getTokenListExport = (data = {}) => {

    let req = {
        params: data
    };

    const url = `${basePath()}/token/tokenlog/export`

    return instance.get(url, req)
}

export const getTokenAdminList = (page = 1, data = {}) => {

    let req = {
        params: data
    };

    const url = `${basePath()}/token/tokenlog/admin/${page}`

    return instance.get(url, req)
}


export const getTokenAdminListExport = (data = {}) => {

    let req = {
        params: data
    };

    const url = `${basePath()}/token/tokenlog/admin/export`

    return instance.get(url, req)
}

export const createToken = params => {
    const url = `${basePath()}/token/create`

    return instance.post(url, params)
}

export const getRewardList = (page = 1, data = {}) => {
    // 0 = Pending
    // 1 = Approved
    // 2 = Reject

    let req = {
        params: data
    };

    const url = `${basePath()}/reward/${page}`

    return instance.get(url, req)
}


export const updateGoldReward = params => {
    const url = `${basePath()}/reward/gold/update`

    return instance.post(url, params)
}

export const updateSilverReward = params => {
    const url = `${basePath()}/reward/silver/update`

    return instance.post(url, params)
}

/*export const getFinancialLogList = (page, data = {}) => {
    const url = `${basePath()}/financiallog/${page}`

    let req = {
        params: data
    }
    return instance.get(url, req)
}

export const getFinancialLogExportList = (data = {}) => {
    const url = `${basePath()}/financiallog/export`

    let req = {
        params: data
    }
    return instance.get(url, req)
}

export const createWallet = params => {
    const url = `${basePath()}/create`

    params = {
        amount: '0.00',
        type: '1',
        transactiontype: '1',
        path: '/path/image/pdf',
        status: '0'
    }

    return instance.post(url, params)
}

export const updateWallet = params => {
    const url = `${basePath()}/update`

    return instance.post(url, params)
}

export const manualCreateWallet = params => {
    const url = `${basePath()}/create`
    return instance.post(url, params)
}*/
