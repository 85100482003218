const initialState = {
    userPermission: [],
    permissionList: [],
    roleList: []
}

//trigger action
const userPermission = (state = initialState, action) => {
    const newState = { ...state }

    switch (action.type) {
        case 'GET_USER_PERMISSION':
            break
        case 'GET_USER_PERMISSION_SUCCESS':
            newState.userPermission = JSON.parse(action.data.permissionlist)
            // newState.token = action.data.token
            break
        case 'GET_PERMISSION_LIST':
            break
        case 'GET_PERMISSION_LIST_SUCCESS':
            newState.permissionList = action.data.list
            // newState.token = action.data.token
            break
        case 'GET_ROLE_LIST':
            break
        case 'GET_ROLE_LIST_SUCCESS':
            newState.roleList = action.data.list
            // newState.token = action.data.token
            break
        case 'RESET_SIDE_BAR':
            return initialState
            // newState.token = action.data.token
            break

        default:
            return state
    }

    return newState
}

export default userPermission
