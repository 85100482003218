import axios from 'axios'
import { hostUrl } from './config'
import { store } from '../store'

export * from './path/login'
export * from './path/report'

export * from './path/available_days'
export * from './path/bank_account'
export * from './path/hot_number'
export * from './path/portal_company'

export * from './path/user'
export * from './path/wallet'
export * from './path/user_permission'
export * from './path/promo'
export * from './path/admin'
export * from './path/draw_type'
export * from './path/draw_time'
export * from './path/draw_mode'
export * from './path/wheel'

// 这里取决于登录的时候将 token 存储在哪里
const token = localStorage.getItem('token')

const instance = axios.create({
    timeout: 5000,
    baseURL: hostUrl()
})

// 设置post请求头
//instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
instance.defaults.headers.post['Content-Type'] = 'application/json'
//axios.defaults.baseURL = "https://apiuq4d.drsoft.cloud";

// 添加请求拦截器
instance.interceptors.request.use(
    config => {
        // 将 token 添加到请求头
        token && (config.headers.Authorization = token)
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

// 添加响应拦截器
instance.interceptors.response.use(
    response => {
        if (response.status === 200) {
            return Promise.resolve(response)
        } else {
            return Promise.reject(response)
        }
    },
    error => {
        // 相应错误处理
        // 比如： token 过期， 无权限访问， 路径不存在， 服务器问题等

        // console.log(JSON.stringify(error))

        switch (error.response.status) {
            case 401:
                let json = error.response
                if (json.data.code == 190) {
                    let request = error.config
                    return refreshToken(request.url, request.method, request.data)
                }

                break
            case 403:
                break
            case 404:
                break
            case 500:
                break
            default:
            //console.log('其他错误信息')
        }
        return Promise.reject(error)
    }
)

export const SetAxiosDefaultJWT = jwt_bearer => {
    instance.defaults.headers.common['Authorization'] = jwt_bearer
}

export const refreshToken = (url, type, params = null) => {
    // console.log('expired refreshToken' + store.getState().login.refreshToken)
    // console.log('expired token' + store.getState().login.token)
    // console.log('request type ' + type)

    let refreshToken = store.getState().login.refreshToken

    let refreshParams = {
        refreshtoken: refreshToken
    }

    // console.log('current refresh token ' + JSON.stringify(refreshParams))

    //axios.defaults.baseURL = baseUrl();

    return instance
        .post('/api/admin/token', refreshParams)
        .then(async response => {
            ////console.log("refresh token "+JSON.stringify(refreshParams))

            if (response) {
                // console.log('get response ' + JSON.stringify(response))

                let data = response.data
                if (data.success) {
                    // console.log('got response ' + JSON.stringify(refreshParams))

                    await store.dispatch({ type: 'REFRESH_TOKEN', refreshToken: data.token })
                    await SetAxiosDefaultJWT(data.token)

                    // console.log('refresh token succefful')
                    // console.log('request here')
                    if (type == 'get') {
                        return instance.get(url)
                    } else {
                        return instance.post(url, params)
                    }
                } else return store.dispatch({ type: 'LOGOUT_ADMIN' })
            }
        })
        .catch(function(error) {
            console.log('show me error ' + error)
            // return errorHandle(error);
        })
}

export default instance
