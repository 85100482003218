import { put, takeEvery, all, fork } from 'redux-saga/effects'

import { store } from '../index'
import * as API from '../../api'

export function* getHotList() {
    yield takeEvery('GET_HOT_LIST', function*(action) {
        // console.log('start GET_HOT_LIST')

        store.dispatch({ type: 'LOADER_SHOW' })

        const response = yield API.getHotList()
        try {
            if (response) {
                let data = response.data

                if (!data.success) alert('Error:\n ' + 'failed to get data')
                else {
                    // console.log('return saga getHotList')
                    // //console.log("get portal activity "+JSON.stringify(data))
                    yield put({ type: 'GET_HOT_LIST_SUCCESS', data })

                    // this.props.history.push("/dashboard");
                }
            }
        } catch (error) {
            //console.log(error)
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })
}

export function* createHotNumber() {
    yield takeEvery('CREATE_HOT_NUMBER', function*(action) {
        store.dispatch({ type: 'LOADER_SHOW' })

        //get params
        let params = action.params
        // console.log('show create params ' + JSON.stringify(params))
        const response = yield API.createHotNumber(params)
        try {
            //  //console.log("show response "+JSON.stringify(response))
            if (response) {
                let data = response.data

                if (data.success) {
                    // //console.log("update wallet activity "+JSON.stringify(data))
                    // this.props.history.push("/dashboard");
                    store.dispatch({ type: 'SHOW_MESSAGE', msg_type: 'CREATE' })
                    yield put({ type: 'GET_HOT_LIST' })
                } else alert('Error:\n ' + 'failed to get data')
            }
        } catch (error) {
            // console.log(error)
        }
        store.dispatch({ type: 'LOADER_HIDE' })
    })
}

export function* updateHotNumber() {
    yield takeEvery('UPDATE_HOT_NUMBER', function*(action) {
        yield put({ type: 'LOADER_SHOW' })
        //get params
        let params = action.params

        const response = yield API.updateHotNumber(params)
        try {
            //  //console.log("show response "+JSON.stringify(response))
            if (response) {
                let data = response.data

                if (data.success) {
                    // //console.log("update wallet activity "+JSON.stringify(data))
                    // this.props.history.push("/dashboard");
                    yield put({ type: 'SHOW_MESSAGE', msg_type: 'UPDATE' })
                    yield put({ type: 'GET_HOT_LIST' })
                } else alert('Error:\n ' + 'failed to get data')
            }
        } catch (error) {
            //console.log(error)
        }

        yield put({ type: 'LOADER_HIDE' })
    })
}

export function* deleteHotNumber() {
    yield takeEvery('DELETE_HOT_NUMBER', function*(action) {
        yield put({ type: 'LOADER_SHOW' })
        //get params
        let params = action.params

        try {
            //  //console.log("show response "+JSON.stringify(response))
            const response = yield API.deleteHotNumber(params)
            if (response) {
                let data = response.data

                if (data.success) {
                    // //console.log("update wallet activity "+JSON.stringify(data))
                    // this.props.history.push("/dashboard");
                    store.dispatch({ type: 'SHOW_MESSAGE', msg_type: 'DELETE' })
                    yield put({ type: 'GET_HOT_LIST' })
                } else alert('Error:\n ' + 'failed to get data')
            }
        } catch (error) {
            //console.log(error)
        }

        yield put({ type: 'LOADER_HIDE' })
    })
}

export default function* hot_saga() {
    yield all([fork(getHotList), fork(createHotNumber), fork(updateHotNumber), fork(deleteHotNumber)])
}
