import { put, takeEvery, all, fork } from 'redux-saga/effects'

import { store } from '../index'
import * as API from '../../api'

export function* getBankNameList() {
    yield takeEvery('GET_BANK_LIST', function*(action) {
        // console.log('start get GET_BANK_LIST')

        store.dispatch({ type: 'LOADER_SHOW' })

        const response = yield API.getBankList()
        //console.log('show me ' + JSON.stringify(response))

        try {
            if (response) {
                let data = response.data

                if (!data.success) alert('Error:\n ' + 'failed to get data')
                else {
                    ////console.log("get GET_BANK_LIST "+JSON.stringify(data))
                    yield put({ type: 'GET_BANK_LIST_SUCCESS', data })

                    // this.props.history.push("/dashboard");
                }
            }
        } catch (err) {
            console.log(err)
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })
}

export function* getBankAccountList() {
    yield takeEvery('GET_BANK_ACCOUNT_LIST', function*(action) {
        // //console.log("start get portal activity")

        store.dispatch({ type: 'LOADER_SHOW' })

        const response = yield API.getBankAccount()
        try {
            if (response) {
                let data = response.data

                if (!data.success) alert('Error:\n ' + 'failed to get data')
                else {
                    // //console.log("get portal activity "+JSON.stringify(data))
                    yield put({ type: 'GET_BANK_ACCOUNT_LIST_SUCCESS', data })

                    // this.props.history.push("/dashboard");
                }
            }
        } catch (err) {
            console.log(err)
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })
}

export function* createBankAccount() {
    yield takeEvery('CREATE_BANK_ACCOUNT', function*(action) {
        //get params
        let params = action.params

        store.dispatch({ type: 'LOADER_SHOW' })
        try {
            const response = yield API.createBankAccount(params)

            //  //console.log("show response "+JSON.stringify(response))
            if (response) {
                let data = response.data

                if (data.success) {
                    store.dispatch({ type: 'SHOW_MESSAGE', msg_type: 'CREATE' })
                } else alert('Error:\n ' + 'failed to get data')

                yield put({ type: 'GET_BANK_LIST' })

                yield put({ type: 'GET_BANK_ACCOUNT_LIST' })
            }
        } catch (err) {
            console.log(err)
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })
}

export function* updateBankAccount() {
    yield takeEvery('UPDATE_BANK_ACCOUNT', function*(action) {
        //get params
        let params = action.params

        store.dispatch({ type: 'LOADER_SHOW' })

        const response = yield API.updateBankAccount(params)
        try {
            //  //console.log("show response "+JSON.stringify(response))
            if (response) {
                let data = response.data

                if (data.success) {
                    // //console.log("update wallet activity "+JSON.stringify(data))
                    // this.props.history.push("/dashboard");
                    store.dispatch({ type: 'SHOW_MESSAGE', msg_type: 'UPDATE' })
                } else alert('Error:\n ' + 'failed to get data')

                yield put({ type: 'GET_BANK_LIST' })

                yield put({ type: 'GET_BANK_ACCOUNT_LIST' })
            }
        } catch (err) {
            console.log(err)
        }
        store.dispatch({ type: 'LOADER_HIDE' })
    })
}

export function* deleteBankAccount() {
    yield takeEvery('DELETE_BANK_ACCOUNT', function*(action) {
        //get params
        let params = action.params

        store.dispatch({ type: 'LOADER_SHOW' })

        const response = yield API.deleteBankAccount(params)
        try {
            //  //console.log("show response "+JSON.stringify(response))
            if (response) {
                let data = response.data

                if (data.success) {
                    // //console.log("update wallet activity "+JSON.stringify(data))
                    // this.props.history.push("/dashboard");
                    store.dispatch({ type: 'SHOW_MESSAGE', msg_type: 'DELETE' })
                } else alert('Error:\n ' + 'failed to get data')

                yield put({ type: 'GET_BANK_LIST' })

                yield put({ type: 'GET_BANK_ACCOUNT_LIST' })
            }
        } catch (err) {
            console.log(err)
        }
        store.dispatch({ type: 'LOADER_HIDE' })
    })
}

export default function* bank_account_saga() {
    yield all([
        fork(getBankNameList),
        fork(getBankAccountList),
        fork(createBankAccount),
        fork(updateBankAccount),
        fork(deleteBankAccount)
    ])
}
