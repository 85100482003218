import { put, takeEvery, all, fork } from 'redux-saga/effects'

import * as API from '../../api'
import { store } from '../index'

export function* listen_report_saga() {
    yield takeEvery('DRAW_REPORT', function*(action) {
        // console.log('start get DRAW_REPORT')

        store.dispatch({ type: 'LOADER_SHOW' })
        const response = yield API.getDrawReport()
        try {
            if (response) {
                let data = response.data

                if (data.success) {
                    yield put({ type: 'DRAW_REPORT_SUCCESS', data })

                    // this.props.history.push("/dashboard");
                } else alert('Error:\n ' + 'failed to get data')
            }
        } catch (error) {
            // console.log(error)
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })

    yield takeEvery('AGENT_DRAW_REPORT', function*(action) {
        //console.log('start get AGENT_DRAW_REPORT')

        store.dispatch({ type: 'LOADER_SHOW' })
        const response = yield API.getAgentDrawReport()
        try {
            if (response) {
                let data = response.data

                if (data.success) {
                    yield put({ type: 'DRAW_REPORT_SUCCESS', data })

                    // this.props.history.push("/dashboard");
                } else alert('Error:\n ' + 'failed to get data')
            }
        } catch (error) {
            //console.log(error)
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })

    yield takeEvery('YEARLY_REPORT', function*(action) {
        //console.log('start get YEARLY_REPORT ')

        yield put({ type: 'LOADER_SHOW' })

        try {
            const response = yield API.getYearlyReport()

            if (response) {
                let data = response.data
                if (data.success) {
                    yield put({ type: 'YEARLY_REPORT_SUCCESS', data })
                }
            }
        } catch (err) {
            //console.log(err)
        }

        yield put({ type: 'LOADER_HIDE' })
    })

    yield takeEvery('AGENT_YEARLY_REPORT', function*(action) {
        //console.log('start get AGENT_YEARLY_REPORT ')

        yield put({ type: 'LOADER_SHOW' })

        try {
            const response = yield API.getAgentYearlyReport()

            if (response) {
                let data = response.data
                if (data.success) {
                    yield put({ type: 'YEARLY_REPORT_SUCCESS', data })
                }
            }
        } catch (err) {
            //console.log(err)
        }

        yield put({ type: 'LOADER_HIDE' })
    })

    yield takeEvery('ACTIVE_USER', function*(action) {
        //console.log('start get ACTIVE_USER ')

        yield put({ type: 'LOADER_SHOW' })

        try {
            const response = yield API.getActiveUser()

            if (response) {
                let data = response.data
                if (data.success) {
                    yield put({ type: 'ACTIVE_USER_SUCCESS', data })
                }
            }
        } catch (err) {
            //console.log(err)
        }

        yield put({ type: 'LOADER_HIDE' })
    })

    yield takeEvery('AGENT_ACTIVE_USER', function*(action) {
        //console.log('start get AGENT_ACTIVE_USER ')

        yield put({ type: 'LOADER_SHOW' })

        try {
            const response = yield API.getAgentActiveUser()

            if (response) {
                let data = response.data
                if (data.success) {
                    yield put({ type: 'ACTIVE_USER_SUCCESS', data })
                }
            }
        } catch (err) {
            //console.log(err)
        }

        yield put({ type: 'LOADER_HIDE' })
    })

    yield takeEvery('AGENT_REPORT', function*(action) {
        //console.log('start get AGENT_REPORT ')

        yield put({ type: 'LOADER_SHOW' })

        try {
            const response = yield API.getAgentReport()

            if (response) {
                let data = response.data
                if (data.success) {
                    yield put({ type: 'AGENT_REPORT_SUCCESS', data })
                }
            }
        } catch (err) {
            //console.log(err)
        }

        yield put({ type: 'LOADER_HIDE' })
    })

    yield takeEvery('FULL_REPORT', function*(action) {
        //console.log('start get FULL_REPORT ')

        yield put({ type: 'LOADER_SHOW' })

        try {
            let params = action.params

            const response = yield API.getReport(action.page, action.params)

            if (response) {
                let data = response.data
                if (data.success) {
                    yield put({ type: 'FULL_REPORT_SUCCESS', data })
                }
            }
        } catch (err) {
            //console.log(err)
        }

        yield put({ type: 'LOADER_HIDE' })
    })

    yield takeEvery('FULL_REPORT_EXPORT', function*(action) {
        //console.log('start get FULL_REPORT_EXPORT_SUCCESS ')

        yield put({ type: 'LOADER_SHOW' })

        try {
            let params = action.params

            const response = yield API.getReportExport(action.params)

            if (response) {
                let data = response.data
                if (data.success) {
                    yield put({ type: 'FULL_REPORT_EXPORT_SUCCESS', data })
                }
            }
        } catch (err) {
            //console.log(err)
        }

        yield put({ type: 'LOADER_HIDE' })
    })

    yield takeEvery('MASTER_COMMISSION_REPORT', function*(action) {
        //console.log('start get MASTER_COMMISSION_REPORT ')

        yield put({ type: 'LOADER_SHOW' })

        try {
            let params = action.params

            const response = yield API.getCommisionReport(params.page, action.params)

            if (response) {
                let data = response.data
                if (data.success) {
                    yield put({ type: 'MASTER_COMMISSION_REPORT_SUCCESS', data })
                }
            }
        } catch (err) {
            //console.log(err)
        }

        yield put({ type: 'LOADER_HIDE' })
    })


    yield takeEvery('UPDATE_COMMISSION_REPORT', function*(action) {
        //console.log('start get MASTER_COMMISSION_REPORT ')

        yield put({ type: 'LOADER_SHOW' })

        try {
            let params = action.params

            const response = yield API.updateCommisionReport(params)

            

            if (response) {
                let data = response.data
                if (data.success) {
                    yield put({ type: 'MASTER_COMMISSION_REPORT',params})
                }
            }
        } catch (err) {
            //console.log(err)
        }

        yield put({ type: 'LOADER_HIDE' })
    })


    yield takeEvery('AGENT_COMMISSION_REPORT', function*(action) {
        //console.log('start get AGENT_COMMISSION_REPORT ')

        yield put({ type: 'LOADER_SHOW' })

        try {
            let params = action.params

            const response = yield API.getAgentCommisionReport(params.page, action.params)

            if (response) {
                let data = response.data
                if (data.success) {
                    yield put({ type: 'AGENT_COMMISSION_REPORT_SUCCESS', data })
                }
            }
        } catch (err) {
            //console.log(err)
        }

        yield put({ type: 'LOADER_HIDE' })
    })

    yield takeEvery('DAILY_REPORT', function*(action) {
        //console.log('start get YEARLY_REPORT ')

        yield put({ type: 'LOADER_SHOW' })

        try {
            const response = yield API.getDailyReport()

            if (response) {
                let data = response.data
                if (data.success) {
                    yield put({ type: 'DAILY_REPORT_SUCCESS', data })
                }
            }
        } catch (err) {
            //console.log(err)
        }

        yield put({ type: 'LOADER_HIDE' })
    })

    yield takeEvery('AGENT_DAILY_REPORT', function*(action) {
        //console.log('start get AGENT_YEARLY_REPORT ')

        yield put({ type: 'LOADER_SHOW' })

        try {
            const response = yield API.getAgentDailyReport()

            if (response) {
                let data = response.data
                if (data.success) {
                    yield put({ type: 'AGENT_DAILY_REPORT_SUCCESS', data })
                }
            }
        } catch (err) {
            //console.log(err)
        }

        yield put({ type: 'LOADER_HIDE' })
    })
}

export default function* report_saga() {
    yield all([fork(listen_report_saga)])
}
