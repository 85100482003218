import { put, takeEvery, all, fork } from 'redux-saga/effects'

import { store } from '../index'
import * as API from '../../api'

export function* getWalletList() {
    yield takeEvery('GET_WALLET_LIST', function*(action) {
        //console.log('start get GET_WALLET_LIST')

        store.dispatch({ type: 'LOADER_SHOW' })
        const response = yield API.getWalletList(action.page, action.status)
        try {
            if (response) {
                let data = response.data

                if (data.success) {
                    //console.log('get wallet activity ' + JSON.stringify(data))
                    yield put({ type: 'GET_WALLET_LIST_SUCCESS', data })

                    // this.props.history.push("/dashboard");
                } else alert('Error:\n ' + 'failed to get data')
            }
        } catch (error) {
            //console.log(error)
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })
}

export function* getFinancialLogList() {
    yield takeEvery('GET_FINANCIAL_LOG_LIST', function*(action) {
        //console.log('start GET_FINANCIAL_LOG_LIST')

        store.dispatch({ type: 'LOADER_SHOW' })

        let page = action.page

        const response = yield API.getFinancialLogList(page, action.params)
        try {
            if (response) {
                let data = response.data

                if (data.success) {
                    //console.log('get wallet activity ' + JSON.stringify(data))
                    yield put({ type: 'GET_FINANCIAL_LOG_LIST_SUCCESS', data })

                    // this.props.history.push("/dashboard");
                } else alert('Error:\n ' + 'failed to get data')
            }
        } catch (error) {
            //console.log(error)
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })
}

export function* getFinancialLogExportList() {
    yield takeEvery('GET_FINANCIAL_LOG_EXPORT_LIST', function*(action) {
        //console.log('start GET_FINANCIAL_LOG_EXPORT_LIST')

        store.dispatch({ type: 'LOADER_SHOW' })

        let page = action.page

        const response = yield API.getFinancialLogExportList(action.params)
        try {
            if (response) {
                let data = response.data

                if (data.success) {
                    ////console.log('get wallet activity ' + JSON.stringify(data))
                    yield put({ type: 'GET_FINANCIAL_LOG_EXPORT_LIST_SUCCESS', data })

                    // this.props.history.push("/dashboard");
                } else alert('Error:\n ' + 'failed to get data')
            }
        } catch (error) {
            //console.log(error)
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })
}

export function* updateWalletActivity() {
    yield takeEvery('UPDATE_WALLET_ACTIVITY', function*(action) {
        store.dispatch({ type: 'LOADER_SHOW' })

        //get params
        let params = action.params

        const response = yield API.updateWallet(params)
        try {
            //  //console.log("show response "+JSON.stringify(response))
            if (response) {
                let data = response.data

                if (data.success) {
                    // //console.log("update wallet activity "+JSON.stringify(data))
                    // this.props.history.push("/dashboard");
                    yield put({ type: 'SHOW_MESSAGE', msg_type: 'SUBMIT' })
                    yield put({ type: 'GET_WALLET_LIST' })
                } else alert('Error:\n ' + 'failed to get data')
            }
        } catch (error) {
            //console.log(error)
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })
}

export function* manaualCreateWalletActivity() {
    yield takeEvery('MANUAL_CREATE_WALLET_ACTIVITY', function*(action) {
        store.dispatch({ type: 'LOADER_SHOW' })

        //get params
        let params = action.params

        const response = yield API.manualCreateWallet(params)
        try {
            //  //console.log("show response "+JSON.stringify(response))
            if (response) {
                let data = response.data

                if (data.success) {
                    // //console.log("update wallet activity "+JSON.stringify(data))
                    // this.props.history.push("/dashboard");
                    yield put({ type: 'SHOW_MESSAGE', msg_type: 'CREATE' })
                } else alert('Error:\n ' + 'failed to get data')
            }
        } catch (error) {
            //console.log(error)
        }

        store.dispatch({ type: 'LOADER_HIDE' })
    })
}

export default function* wallet_saga() {
    yield all([
        fork(getWalletList),
        fork(updateWalletActivity),
        fork(getFinancialLogList),
        fork(getFinancialLogExportList),
        fork(manaualCreateWalletActivity)
    ])
}
