import instance from "../index";

// Base Path
export const basePath = () => {
  return "/api/available";
};


export const getAvailableDays = () => {
  const url = `${basePath()}`;
 
  return instance.get(url);
};


