const initialState = {
    drawReportList: [],
    yearlyReportList: [],
    yearlyReportList: [],
    agentReportList: [],
    fullReportList: [],
    fullReportListPage: 1,
    fullReportExportList:[],
    activeUser: 0,
    masterComsReportList: [],
    masterComsReportPage: 1,
    agentComsReportList: [],
    agentComsReportPage: 1
}

//trigger action
const report = (state = initialState, action) => {
    const newState = { ...state }

    switch (action.type) {
        case 'DRAW_REPORT':
            break
        case 'DRAW_REPORT_SUCCESS':
            newState.drawReportList = action.data.list
            // newState.token = action.data.token
            break
        case 'YEARLY_REPORT':
            break
        case 'YEARLY_REPORT_SUCCESS':
            newState.yearlyReportList = action.data.list
            break
        case 'ACTIVE_USER':
            break
        case 'ACTIVE_USER_SUCCESS':
            newState.activeUser = action.data.total
            break
        case 'AGENT_REPORT':
            break
        case 'AGENT_REPORT_SUCCESS':
            newState.agentReportList = action.data.list
            break
        case 'FULL_REPORT':
            break
        case 'FULL_REPORT_SUCCESS':
            newState.fullReportList = action.data.list
            newState.fullReportListPage = action.data.total_page
            break
        case 'FULL_REPORT_EXPORT':
            break
        case 'FULL_REPORT_EXPORT_SUCCESS':
            newState.fullReportExportList = action.data.list
            break
        case 'MASTER_COMMISSION_REPORT_SUCCESS':
            newState.masterComsReportList = action.data.list
            newState.masterComsReportPage = action.data.total_page
        case 'UPDATE_COMMISSION_REPORT':
            break
        case 'AGENT_COMMISSION_REPORT_SUCCESS':
            newState.agentComsReportList = action.data.list
            newState.agentComsReportPage = action.data.total_page
            break
        case 'DAILY_REPORT':
            break
        case 'DAILY_REPORT_SUCCESS':
            newState.yearlyReportList = action.data.list
            break
        case 'AGENT_DAILY_REPORT':
            break
        case 'AGENT_DAILY_REPORT_SUCCESS':
            newState.yearlyReportList = action.data.list
            break
        default:
            return state
    }

    return newState
}

export default report
