const initialState = {
    annoucementList: [],
    annoucementListPage: 0,
    telegramList: [],
    telegramListPage: 0,
    bannerList: [],
    bannerListPage: 0,
    whatsappsList: [],
    whatsappsListPage: 0
}

//trigger action
const promoReducer = (state = initialState, action) => {
    const newState = { ...state }

    switch (action.type) {
        case 'GET_ANNOUCEMENT_LIST':
            break
        case 'GET_ANNOUCEMENT_LIST_SUCCESS':
            newState.annoucementList = action.data.list
            newState.annoucementListPage = action.data.total_page
            break
        case 'GET_TELEGRAM':
            break
        case 'GET_TELEGRAM_SUCCESS':
            newState.telegramList = action.data.list
            newState.telegramListPage = action.data.total_page
            break
        case 'GET_BANNER_SUCCESS':
            newState.bannerList = action.data.list
            newState.bannerListPage = action.data.total_page
            break
        //whatsapps

        case 'GET_WHATSAPPS':
            break
        case 'GET_WHATSAPPS_SUCCESS':
            newState.whatsappsList = action.data.list
            newState.whatsappsListPage = action.data.total_page
            break

        default:
            return state
    }

    return newState
}

export default promoReducer
