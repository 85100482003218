import instance from '../index'

// Base Path
export const basePath = () => {
    return '/api/drawtype'
}

export const getDrawList = () => {
    const url = `${basePath()}`

    return instance.get(url)
}



export const updateDraw = params => {
    const url = `${basePath()}/update`

    return instance.post(url, params)
}

