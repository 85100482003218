import instance from '../index'

// Base Path
export const basePath = () => {
    return '/api/announcement'
}

export const getAnnoucementList = (page = 1) => {
    const url = `${basePath()}/${page}`

    return instance.get(url)
}

export const createAnnoucement = params => {
    const url = `${basePath()}/create`

    return instance.post(url, params)
}

export const updateAnnoucement = params => {
    const url = `${basePath()}/update`

    return instance.post(url, params)
}

export const deleteAnnoucement = params => {
    const url = `${basePath()}/delete`

    return instance.post(url, params)
}

export const getTelegramList = (page = 1) => {
    const url = `/api/comm/${page}`

    return instance.get(url)
}

export const createTelegram = params => {
    const url = `/api/comm/create`

    return instance.post(url, params)
}

export const updateTelegram = params => {
    const url = `/api/comm/update`

    return instance.post(url, params)
}

export const deleteTelegram = params => {
    const url = `/api/comm/delete`

    return instance.post(url, params)
}

//whatsapps
export const getWhatsappsList = (page = 1) => {
    const url = `/api/commwhatsapp/${page}`

    return instance.get(url)
}

export const createWhatsapps = params => {
    const url = `/api/commwhatsapp/create`

    return instance.post(url, params)
}

export const updateWhatsapps = params => {
    const url = `/api/commwhatsapp/update`

    return instance.post(url, params)
}

export const deleteWhatsapps = params => {
    const url = `/api/commwhatsapp/delete`

    return instance.post(url, params)
}

export const uploadBanner = file => {
    const url = `/api/banner/file/upload`

    return instance.post(url, file)
}

export const createBanner = path => {
    const url = `/api/banner/create`

    let params = {
        path: path,
        status: '1'
    }

    return instance.post(url, params)
}

export const updateBanner = params => {
    const url = `/api/banner/update`

    return instance.post(url, params)
}

export const deleteBanner = params => {
    const url = `/api/banner/delete`

    return instance.post(url, params)
}

export const getBannerList = (page = 1) => {
    const url = `/api/banner`

    return instance.get(url)
}
