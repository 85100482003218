const initialState = {
    drawTimeList: []
}

//trigger action
const drawtime = (state = initialState, action) => {
    const newState = { ...state }

    switch (action.type) {
        case 'GET_DRAW_TIME_LIST':
            break
        case 'GET_DRAW_TIME_LIST_SUCCESS':
            newState.drawTimeList = action.data.list
            // newState.token = action.data.token
            break
        case 'UPDATE_DRAW_TIME':
            // console.log('here UPDATE_DRAW_TIME')
            // newState.token = action.data.token
            break

        default:
            return state
    }

    return newState
}

export default drawtime
